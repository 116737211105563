import React, { Fragment } from "react";
import { Col, Row, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/Main.scss";
// import "../styles/Main.css";
import { useTranslation } from "react-i18next";
import admision from "../pages/Imgs/admission.png";

import DefaultHeader from "../components/DefaultHeader";

const HowToApply = (props) => {
  const [t, i18n] = useTranslation();

  return (
    <Fragment>
      <DefaultHeader title={t("Admission Policy")} backgroundImage={admision} />
      <div className="BodyDiv">
        <Container className="MainPageBodyContainer">
          <div className="BodyTitle">
            <Row>
              <Col xs={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}></Col>
              <Col xs={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div style={{ color: "#09154E" }}>
                  <svg
                    width="50"
                    height="35"
                    viewBox="0 0 215 259"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3480_4927)">
                      <path
                        d="M124.566 163.976C124.158 162.903 122.956 162.362 121.882 162.77C120.809 163.177 120.268 164.38 120.675 165.453L154.662 254.839H62.3182L77.8801 213.907H121.716C122.864 213.907 123.796 212.975 123.796 211.827C123.796 210.679 122.864 209.747 121.716 209.747H79.4613L85.5363 193.766H110.718C111.867 193.766 112.799 192.834 112.799 191.686C112.799 190.537 111.867 189.605 110.718 189.605H87.1216L96.3048 165.453C96.7126 164.38 96.1716 163.177 95.0981 162.77C94.0246 162.362 92.8221 162.903 92.4143 163.976L57.3542 256.183C57.1129 256.824 57.1961 257.539 57.5872 258.105C57.9742 258.667 58.615 259.004 59.3015 259.004H157.679C158.361 259.004 159.002 258.667 159.393 258.105C159.78 257.543 159.867 256.824 159.626 256.183L124.566 163.976Z"
                        fill="#061041"
                      />
                      <path
                        d="M212.92 142.45H115.304C114.022 139.5 112.445 137.07 110.619 135.073C110.606 135.061 112.82 133.759 113.032 133.655C113.91 133.218 114.817 132.843 115.741 132.523C117.742 131.828 119.827 131.383 121.924 131.083C127.025 130.355 132.222 130.422 137.357 130.588C143.569 130.788 149.777 131.179 155.985 131.47C162.193 131.761 168.243 131.99 174.381 132.061C180.502 132.128 186.627 132.04 192.735 131.641C196.059 131.424 199.376 131.108 202.679 130.676C203.82 130.526 204.618 129.477 204.469 128.337C204.319 127.197 203.266 126.394 202.13 126.548C196.259 127.33 190.334 127.692 184.413 127.842C178.263 127.996 172.109 127.909 165.959 127.709C159.809 127.509 154.021 127.214 148.059 126.91C145.034 126.91 141.988 126.594 138.963 126.469C135.938 126.344 133.2 126.274 130.321 126.315C125.086 126.39 119.748 126.827 114.755 128.508C112.162 129.377 109.687 130.601 107.498 132.244C107.456 132.273 104.24 130.193 103.895 130.018C102.501 129.315 101.04 128.745 99.5505 128.283C96.4589 127.322 93.2384 126.823 90.0136 126.557C81.4879 125.858 72.9205 126.611 64.3989 127.039C55.8773 127.468 48.1296 127.826 39.9866 127.905C30.9366 127.988 21.8491 127.738 12.8698 126.548C11.7297 126.399 10.6853 127.197 10.5313 128.337C10.3774 129.477 11.1804 130.522 12.3205 130.676C28.4026 132.814 44.7676 132.165 60.9203 131.383C68.822 131 76.7486 130.393 84.6668 130.505C90.463 130.588 96.617 131.017 101.901 133.63C102.118 133.738 104.39 135.065 104.377 135.082C102.551 137.079 100.974 139.508 99.692 142.458H2.08047C0.932051 142.458 0 143.39 0 144.539C0 145.687 0.932051 146.619 2.08047 146.619H101.094C101.956 146.619 102.725 146.091 103.037 145.283C104.215 142.217 105.709 139.766 107.502 137.807C109.3 139.771 110.789 142.221 111.967 145.283C112.275 146.086 113.049 146.619 113.91 146.619H212.924C214.072 146.619 215.004 145.687 215.004 144.539C215.004 143.39 214.072 142.458 212.924 142.458L212.92 142.45Z"
                        fill="#061041"
                      />
                      <path
                        d="M84.24 114.387C84.9391 115.061 85.7796 115.535 86.6451 115.219C87.5646 114.878 87.9599 113.725 87.7685 112.76C87.5771 111.795 86.9571 110.988 86.358 110.21C84.2317 107.464 82.1554 104.676 80.129 101.855C74.5742 94.1165 69.2981 85.862 67.3175 76.5464C64.151 61.664 70.4923 45.2589 82.6797 36.1472C94.8671 27.0356 112.21 25.5336 125.908 32.1614C133.734 35.9475 140.367 42.2092 144.457 49.8855C148.543 57.5576 150.041 66.611 148.485 75.1651C146.754 84.6679 141.486 93.1222 135.902 101.006C133.734 104.069 131.504 107.089 129.211 110.06C128.379 111.142 127.489 112.394 127.718 113.738C127.888 114.741 129.033 115.406 129.998 115.227C131.101 115.019 131.7 113.754 132.332 112.927C134.321 110.33 136.273 107.709 138.182 105.055C144.291 96.563 150.099 87.5096 152.271 77.2787C155.584 61.7057 149.442 44.5849 137.196 34.4081C124.951 24.2313 107.196 21.294 92.2082 26.6611C81.8267 30.3807 72.8141 37.903 67.5047 47.568C62.1954 57.233 60.6933 68.97 63.6143 79.6003C65.7322 87.3098 70.0138 94.2289 74.4826 100.857C77.3578 105.117 80.3329 109.307 83.4078 113.426C83.6533 113.75 83.9321 114.091 84.2442 114.387H84.24Z"
                        fill="#061041"
                      />
                      <path
                        d="M108.489 51.2211C115.954 51.2211 122.025 57.2914 122.025 64.7555C122.025 65.9038 122.957 66.8358 124.105 66.8358C125.254 66.8358 126.186 65.9038 126.186 64.7555C126.186 54.9989 118.247 47.0605 108.489 47.0605C98.7321 47.0605 90.793 54.9989 90.793 64.7555C90.793 74.512 98.7321 82.4504 108.489 82.4504C109.638 82.4504 110.57 81.5184 110.57 80.3701C110.57 79.2218 109.638 78.2898 108.489 78.2898C101.025 78.2898 94.9539 72.2195 94.9539 64.7555C94.9539 57.2914 101.025 51.2211 108.489 51.2211Z"
                        fill="#061041"
                      />
                      <path
                        d="M139.834 18.5357C149.683 22.9126 158.625 33.78 160.631 43.8112C160.83 44.8014 161.696 45.4837 162.669 45.4837C162.807 45.4837 162.944 45.4713 163.081 45.4421C164.209 45.2175 164.937 44.1191 164.712 42.9957C162.428 31.5749 152.675 19.6882 141.528 14.7329C140.475 14.2669 139.247 14.7412 138.781 15.7897C138.315 16.8382 138.786 18.0697 139.838 18.5357H139.834Z"
                        fill="#061041"
                      />
                      <path
                        d="M145.824 4.07738C155.664 6.98978 176.248 29.8813 178.133 45.3544C178.262 46.4112 179.161 47.1851 180.197 47.1851C180.28 47.1851 180.368 47.1809 180.451 47.1685C181.591 47.0312 182.402 45.991 182.265 44.851C180.114 27.1852 158.09 3.36592 147.005 0.0873852C145.903 -0.23714 144.746 0.391107 144.421 1.49366C144.097 2.59621 144.725 3.75285 145.828 4.07738H145.824Z"
                        fill="#061041"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3480_4927">
                        <rect width="215" height="259" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  {t("Admission Policy")}
                </div>
              </Col>
            </Row>
          </div>

          <Row>
            <Col className="BodyCol">
              <div className="BodyText">
                <ul style={{ listStyleType: "none" }}>
                  <li className="facultyDesign">
                    {t(
                      "The Faculty of Computing and Information Technology awards a bachelor's degree in the following disciplines :"
                    )}
                  </li>
                  <p
                    className="aboutuniP1"
                    style={{ marginTop: "1%", color: "#061041" }}
                  >
                    <ul
                      style={{ listStyleType: "decimal", marginRight: "-2%" }}
                    >
                      <li>{t("Information Technology ")}</li>
                      <li>{t("Artificial Intelligence ")}</li>
                      <li>{t("Data Science ")}</li>
                    </ul>
                  </p>

                  <li className="facultyDesign">
                    {t(
                      "The Faculty of Business Studies and Business Administration grants a bachelor's degree in the following disciplines :"
                    )}
                  </li>
                  <ul style={{ listStyleType: "decimal", marginRight: "-2%" }}>
                    <p className="aboutuniP1">
                      <li>{t("Finance and Investment")}</li>
                      <li>
                        {t("Management")}

                        <ul
                          style={{
                            listStyleType: "decimal",
                            marginRight: "-2%",
                          }}
                        >
                          <li className=" Subheader">
                            {t("Sub-Disciplines:")}
                            <ul>
                              <p className="aboutuniP1">
                                <li>{t("Accounting")}</li>
                                <li>{t("Marketing")}</li>
                                <li>{t("Business information systems")}</li>
                                <li>{t("Supply chain management")}</li>
                              </p>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </p>
                  </ul>

                  <li className="facultyDesign">
                    {t("Conditions for enrollment in various programs")}
                  </li>
                  <ol>
                    <li className=" Subheader">
                      {t(
                        "The Faculty of Computing and Information Technology (ICT) accepts the following certificates for the current year and previous years for a period of five years:"
                      )}
                    </li>
                    <ul
                      style={{ listStyleType: "decimal", marginRight: "-3%" }}
                    >
                      <li className="aboutuniP1">
                        {t(
                          "General High School Science Division (Sport or Science)."
                        )}
                      </li>
                      <li className="aboutuniP1">
                        {t("Floral Secondary Division (Science).")}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "Arab High School (Scientific Division) with documentation from Egyptian Foreign Affairs."
                        )}
                      </li>

                      <li className="aboutuniP1">
                        {" "}
                        {t("Canadian Secondary School")} {t("")}
                      </li>
                      <li className="aboutuniP1">
                        {t("German Secondary School (Abitur)")}
                        {t("")}
                      </li>
                      <li className="aboutuniP1">
                        {t("French Baccalaureate")}
                        {t("")}
                      </li>
                      <li className="aboutuniP1">
                        {" "}
                        {t(
                          "The university accepts all foreign and Arab certificates."
                        )}
                        {t("")}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "The student must submit a complete academic record from the first grade of primary school to the twelfth grade of secondary school, certified by the educational administration of the school, in case the student was studying in Egypt."
                        )}
                      </li>

                      <li className="aboutuniP1">
                        {t("English Secondary IGCSE")}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "The College accepts transfers from corresponding and non-corresponding colleges, in accordance with the rules of transfer applicable by the Council of Private and Private Universities."
                        )}
                      </li>

                      <li className="aboutuniP1">
                        {t(
                          "The student must submit an academic record from the first grade of primary school to the twelfth grade of secondary school, certified by the educational administration of the school, in case the student was studying in Egypt."
                        )}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "In case the student studied any grades outside the Arab Republic of Egypt, the student must have the grades studied outside Egypt certified by the Egyptian Ministry of Foreign Affairs, in addition to the results of IGCSE."
                        )}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "Original birth certificate, Form 2 Jund and 6 Jund in case the student exceeds 19 years old, and 6 personal photos."
                        )}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "In case the student completed the IGCSE in just 11 years and wants to enroll in the university, they must bring a decision from the educational administration of the Ministry of Education allowing their enrollment in the university, whether the student studied the English secondary education inside or outside Egypt. "
                        )}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "The results of IGCSE must be certified by the British Council in Cairo with a Golden Stamp, the General Examination Authority, and the Egyptian Ministry of Foreign Affairs. In case the student studied IGCSE outside Egypt, the student must have the results certified by the British Council in Cairo with a Golden Stamp and certified by the Ministry of Foreign Affairs of the country where the student studied or the embassy of that country in the Arab Republic of Egypt and the Egyptian Ministry of Foreign Affairs or the Egyptian embassy in the country where the student studied."
                        )}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "The student must have passed Arabic Language and Religious Education according to the secondary education system. In case the student has not passed them, they are required to pass them in the supplementary tests conducted by the Secretariat of the Council of Private and National Universities."
                        )}
                      </li>
                    </ul>
                    <br></br>
                    <li className=" Subheader">
                      {t(
                        "The School Business Studies and Business Administration accepts the following certificates for the current year, and previous years, for a period of five years 2020"
                      )}
                    </li>
                    <ul
                      style={{ listStyleType: "decimal", marginRight: "-3%" }}
                    >
                      <li className="aboutuniP1">
                        {t("High school is scientific or literary.")}
                      </li>
                      <li className="aboutuniP1">
                        {t("Floral high school is scientific or literary.")}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "Arab secondary school is scientific or literary with documentation from Egyptian foreign."
                        )}
                      </li>
                      <li className="aboutuniP1">{t("American Diploma ")}</li>
                      <li className="aboutuniP1">
                        {t(
                          "English Secondary I G provided that you pass 8 courses at a minimum C with one being English or English literature."
                        )}
                      </li>
                      <li className="aboutuniP1">
                        {t("Diploma in Commercial Schools.")}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "The College accepts transfers from corresponding and non-corresponding colleges, in accordance with the rules of transfer applicable by the Council of Private and Private Universities."
                        )}
                      </li>
                    </ul>
                    <br></br>
                    <li className="Subheader">
                      {t("Faculty of Educational Studies")}
                    </li>
                    <ul
                      style={{ listStyleType: "decimal", marginRight: "-3%" }}
                    >
                      <li className="aboutuniP1">
                        {t(
                          "General Diploma in Education accepts those with a recognized higher qualification."
                        )}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "The vocational diploma in education accepts higher educational qualifications with at least good recognition."
                        )}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "The private diploma in education accepts the recipients of a public diploma or professional diploma with at least a good recognition."
                        )}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "Master's degree in education accepts at least a good diploma."
                        )}
                      </li>
                    </ul>

                    <br></br>
                    <li className="Subheader">
                      {t(
                        "Master of Software Engineering (Computer College grants to holders):"
                      )}
                    </li>
                    <ul
                      style={{ listStyleType: "decimal", marginRight: "-3%" }}
                    >
                      <li className="aboutuniP1">
                        {t(
                          "Bachelor of Computing or any qualification related to computer science with at least a good rating."
                        )}
                      </li>
                      <li className="aboutuniP1">{t("Pass TOEFL")}</li>
                    </ul>
                  </ol>

                  <br></br>
                  <li className="facultyDesign">
                    {t("Papers required for submission")}
                  </li>
                  <p className="Subheader" style={{}}>
                    {" "}
                    {t(
                      "Special papers required for each certificate to certify and review the certificate at the Admission and Registration Office of the Ministry"
                    )}
                  </p>

                  <ol>
                    <li className="Subheader">
                      {t(
                        "Egyptian General High School, Floral High School and artistic diplomas."
                      )}
                    </li>
                    <ul
                      style={{
                        listStyleType: "decimal",
                        marginRight: "-3%",
                        marginTop: "1%",
                      }}
                    >
                      <li className="aboutuniP1">
                        {t(
                          "Origin of the GCSE, Azharu, technical diploma or official extract from the General Department of Examinations. Not the System and Observer Committee + 2 copies of the certificate."
                        )}
                      </li>
                    </ul>

                    <li className="Subheader">{t("Saudi High School")}</li>
                    <ul
                      style={{ listStyleType: "decimal", marginRight: "-3%" }}
                    >
                      <li className="aboutuniP1">
                        {t(
                          "The student must have resided in Saudi Arabia for at least one year (the year they obtained their secondary school certificate) and provide proof of this. In case of the semester system, the student must have resided for three years, which is the current system in the (Curriculum System) for three years."
                        )}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "Saudi secondary education is divided into the semester system and the curriculum system. The certificate must be approved by the Saudi Ministry of Education and the Egyptian embassy in Saudi Arabia. If not, it can be approved by the Saudi embassy, then the Egyptian Ministry of Foreign Affairs, along with proof of residence for both the student and their guardian in the Kingdom during their studies, and the original birth certificate containing the triplet number for males."
                        )}
                      </li>

                      <li className="aboutuniP1">
                        {t(
                          "Submit (Form 2 Jund and 6 Jund) if the student is over 19 years old."
                        )}
                      </li>
                      <li className="aboutuniP1">
                        {t("Six personal photos.")}
                      </li>

                      <li className="aboutuniP1">
                        {t(
                          "Provide a copy of the aptitude test certificate along with the student’s secret number for their account on the measurement and capabilities website."
                        )}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "A student cannot be accepted without the aptitude certificate."
                        )}
                      </li>

                      <li className="aboutuniP1">
                        {t(
                          "Aptitude test statement + two copies of the certificate."
                        )}
                      </li>
                    </ul>

                    <li className="Subheader">
                      {t("Other Arab High Schools:")}
                    </li>
                    <ul
                      style={{ listStyleType: "decimal", marginRight: "-3%" }}
                    >
                      <li className="aboutuniP1">
                        {t(
                          "Original secondary certificate verified by the embassy and the Egyptian Ministry of Foreign Affairs, and approved + by the consulate or embassy."
                        )}
                      </li>
                    </ul>

                    <li className="Subheader">
                      {t("IG English High school:")}
                    </li>
                    <ul
                      style={{ listStyleType: "decimal", marginRight: "-3%" }}
                    >
                      <li className="aboutuniP1">
                        {t(
                          "Original certificate for 8 O Level subjects with a minimum grade of 'C,' one of which must be English."
                        )}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "All documents must be certified by the following official entities: (British Council + Egyptian Ministry of Foreign Affairs + relevant educational authority)."
                        )}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "IGCSE results must be certified by the British Council in Cairo with a Golden Stamp and the General Examinations Authority and the Egyptian Ministry of Foreign Affairs. If the student studied IGCSE outside Egypt, they must have their results certified by the British Council in Cairo with a Golden Stamp and by the Ministry of Foreign Affairs of the country where the student studied or the embassy of that country in Egypt, along with the Egyptian Ministry of Foreign Affairs or the Egyptian embassy in the country where the student studied."
                        )}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "The student must have passed Arabic language and religious education according to the general secondary system from inside Egypt. If the student has not passed them, they must pass them in the complementary subject tests conducted by the Private and National Universities Council Secretariat. For students who obtained these subjects outside Egypt, they must provide proof that they studied Arabic language and religious education according to that country's secondary system."
                        )}
                      </li>
                    </ul>

                    <li className="Subheader">{t("American High School:")}</li>

                    <ul
                      style={{ listStyleType: "decimal", marginRight: "-3%" }}
                    >
                      <li className="aboutuniP1">
                        {t(
                          "The student must submit an educational sequence from the first primary grade to the twelfth secondary grade, certified by the relevant educational authority if the student studied in Egypt. If the student studied any grades outside Egypt, they must have their educational sequence certified by the Egyptian Ministry of Foreign Affairs, along with submitting the school certificate."
                        )}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "Original birth certificate and a copy of the private education statement, school certificate."
                        )}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          " Results (ACT I/EST I/SAT I) and (ACT II/EST II/SAT II) if the student has passed them."
                        )}
                      </li>
                      <li className="aboutuniP1">
                        {t(" Form 2 Jund and 6 Jund and six personal photos.")}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "The school certificate must be approved by the issuing authority (Gonqia, Advanced, or CITA) and the General Examinations Authority. If the student studied the diploma outside Egypt, they must have their certificate certified by the issuing authority and the educational bodies of that country, as well as the Egyptian embassy, or have it certified by the Egyptian Ministry of Foreign Affairs if it is not authenticated by the Egyptian embassy."
                        )}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "Certification of (ACT I/EST I) by the General Examinations Authority and SAT I by AMIDEAST, along with providing the password + username for the student’s account on the board associated with the issued certificate."
                        )}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "Certification of (ACT II/EST II) by the General Examinations Authority and SAT II by AMIDEAST, along with providing the password + username for the student’s account on the board associated with the issued certificate."
                        )}
                      </li>
                      <li className="aboutuniP1">
                        {t(
                          "The student must have passed Arabic language and religious education according to the general secondary system, and if the student has not passed them, they must pass them in the complementary subject tests conducted by the Private and National Universities Council Secretariat."
                        )}
                      </li>
                    </ul>
                  </ol>
                  <br></br>
                  <li className="facultyDesign">
                    {t(
                      "Documents Required for All Certificates to Open an Acceptance and Registration File at the University:"
                    )}
                  </li>
                  <p className="aboutuniP1">
                    <ul
                      style={{ listStyleType: "decimal", marginRight: "-2%" }}
                    >
                      <li>
                        {t(
                          "Original recent birth certificate with the triplet number for males + two copies."
                        )}
                      </li>
                      <li>
                        {t("Two copies of the student’s ID (both sides).")}
                      </li>
                      <li>
                        {" "}
                        {t("Two copies of the guardian's ID (both sides).")}
                      </li>
                      <li> {t("Six personal photos.")}</li>
                      <li>
                        {" "}
                        {t(
                          "Very important: Form 2 Jund + Form 6 Jund for males – or a minor’s form for those under 19 years old."
                        )}
                      </li>
                      <li>
                        {" "}
                        {t("Two copies of the passport (if available).")}
                      </li>
                      <li> {t("Application for admission.")}</li>
                    </ul>
                  </p>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default HowToApply;
