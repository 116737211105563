import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/Main.scss";
import { useTranslation } from "react-i18next";
import DefaultHeader from "../components/DefaultHeader";
import quality from "../pages/Imgs/quality.png";
import pdf1 from "../pages/pdfs/2Computer Faculty Structure and Electronic University Approved.pdf";
import pdf2 from "../pages/pdfs/Student Directory College Computers.pdf";
import pdf3 from "../pages/pdfs/3 Regulation of Quality Assurance Unit of Egyptian University Computers.pdf";
import pdf4 from "../pages/pdfs/4Self-study.pdf";
import pdf5 from "../pages/pdfs/5. Strategic Plan.pdf";
import pdf6 from "../pages/pdfs/7. Teaching, Learning and Evaluation Strategy.pdf";
import pdf7 from "../pages/pdfs/Technological Structure College of Computers.pdf";
import pdf8 from "../pages/pdfs/Core Values and Ethics Document.pdf";
import pdf9 from "../pages/pdfs/Training Plan College of Computers 2021-2026.pdf";
import pdf10 from "../pages/pdfs/Community Service Plan 2021-2026.pdf";
import pdf11 from "../pages/pdfs/Support for students with special needs.pdf";
import pdf12 from "../pages/pdfs/Field Training Manual.pdf";
import pdf13 from "../pages/pdfs/Examination Management Directory.pdf";
function FCIQuality() {
  const [t, i18n] = useTranslation();
  const url = window.location.href.split("/");
  const urlPage = url[3];

  return (
    <Fragment>
      <DefaultHeader title={t("Quality")} backgroundImage={quality} />
      <div className="BodyDiv">
        <Container className="MainPageBodyContainer">
          <div className="BodyTitle">
            <Row>
              <Col xs={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}></Col>
              <Col xs={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div style={{ color: "#09154E" }}>
                  <svg
                    width="50"
                    height="35"
                    viewBox="0 0 215 259"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3480_4927)">
                      <path
                        d="M124.566 163.976C124.158 162.903 122.956 162.362 121.882 162.77C120.809 163.177 120.268 164.38 120.675 165.453L154.662 254.839H62.3182L77.8801 213.907H121.716C122.864 213.907 123.796 212.975 123.796 211.827C123.796 210.679 122.864 209.747 121.716 209.747H79.4613L85.5363 193.766H110.718C111.867 193.766 112.799 192.834 112.799 191.686C112.799 190.537 111.867 189.605 110.718 189.605H87.1216L96.3048 165.453C96.7126 164.38 96.1716 163.177 95.0981 162.77C94.0246 162.362 92.8221 162.903 92.4143 163.976L57.3542 256.183C57.1129 256.824 57.1961 257.539 57.5872 258.105C57.9742 258.667 58.615 259.004 59.3015 259.004H157.679C158.361 259.004 159.002 258.667 159.393 258.105C159.78 257.543 159.867 256.824 159.626 256.183L124.566 163.976Z"
                        fill="#061041"
                      />
                      <path
                        d="M212.92 142.45H115.304C114.022 139.5 112.445 137.07 110.619 135.073C110.606 135.061 112.82 133.759 113.032 133.655C113.91 133.218 114.817 132.843 115.741 132.523C117.742 131.828 119.827 131.383 121.924 131.083C127.025 130.355 132.222 130.422 137.357 130.588C143.569 130.788 149.777 131.179 155.985 131.47C162.193 131.761 168.243 131.99 174.381 132.061C180.502 132.128 186.627 132.04 192.735 131.641C196.059 131.424 199.376 131.108 202.679 130.676C203.82 130.526 204.618 129.477 204.469 128.337C204.319 127.197 203.266 126.394 202.13 126.548C196.259 127.33 190.334 127.692 184.413 127.842C178.263 127.996 172.109 127.909 165.959 127.709C159.809 127.509 154.021 127.214 148.059 126.91C145.034 126.91 141.988 126.594 138.963 126.469C135.938 126.344 133.2 126.274 130.321 126.315C125.086 126.39 119.748 126.827 114.755 128.508C112.162 129.377 109.687 130.601 107.498 132.244C107.456 132.273 104.24 130.193 103.895 130.018C102.501 129.315 101.04 128.745 99.5505 128.283C96.4589 127.322 93.2384 126.823 90.0136 126.557C81.4879 125.858 72.9205 126.611 64.3989 127.039C55.8773 127.468 48.1296 127.826 39.9866 127.905C30.9366 127.988 21.8491 127.738 12.8698 126.548C11.7297 126.399 10.6853 127.197 10.5313 128.337C10.3774 129.477 11.1804 130.522 12.3205 130.676C28.4026 132.814 44.7676 132.165 60.9203 131.383C68.822 131 76.7486 130.393 84.6668 130.505C90.463 130.588 96.617 131.017 101.901 133.63C102.118 133.738 104.39 135.065 104.377 135.082C102.551 137.079 100.974 139.508 99.692 142.458H2.08047C0.932051 142.458 0 143.39 0 144.539C0 145.687 0.932051 146.619 2.08047 146.619H101.094C101.956 146.619 102.725 146.091 103.037 145.283C104.215 142.217 105.709 139.766 107.502 137.807C109.3 139.771 110.789 142.221 111.967 145.283C112.275 146.086 113.049 146.619 113.91 146.619H212.924C214.072 146.619 215.004 145.687 215.004 144.539C215.004 143.39 214.072 142.458 212.924 142.458L212.92 142.45Z"
                        fill="#061041"
                      />
                      <path
                        d="M84.24 114.387C84.9391 115.061 85.7796 115.535 86.6451 115.219C87.5646 114.878 87.9599 113.725 87.7685 112.76C87.5771 111.795 86.9571 110.988 86.358 110.21C84.2317 107.464 82.1554 104.676 80.129 101.855C74.5742 94.1165 69.2981 85.862 67.3175 76.5464C64.151 61.664 70.4923 45.2589 82.6797 36.1472C94.8671 27.0356 112.21 25.5336 125.908 32.1614C133.734 35.9475 140.367 42.2092 144.457 49.8855C148.543 57.5576 150.041 66.611 148.485 75.1651C146.754 84.6679 141.486 93.1222 135.902 101.006C133.734 104.069 131.504 107.089 129.211 110.06C128.379 111.142 127.489 112.394 127.718 113.738C127.888 114.741 129.033 115.406 129.998 115.227C131.101 115.019 131.7 113.754 132.332 112.927C134.321 110.33 136.273 107.709 138.182 105.055C144.291 96.563 150.099 87.5096 152.271 77.2787C155.584 61.7057 149.442 44.5849 137.196 34.4081C124.951 24.2313 107.196 21.294 92.2082 26.6611C81.8267 30.3807 72.8141 37.903 67.5047 47.568C62.1954 57.233 60.6933 68.97 63.6143 79.6003C65.7322 87.3098 70.0138 94.2289 74.4826 100.857C77.3578 105.117 80.3329 109.307 83.4078 113.426C83.6533 113.75 83.9321 114.091 84.2442 114.387H84.24Z"
                        fill="#061041"
                      />
                      <path
                        d="M108.489 51.2211C115.954 51.2211 122.025 57.2914 122.025 64.7555C122.025 65.9038 122.957 66.8358 124.105 66.8358C125.254 66.8358 126.186 65.9038 126.186 64.7555C126.186 54.9989 118.247 47.0605 108.489 47.0605C98.7321 47.0605 90.793 54.9989 90.793 64.7555C90.793 74.512 98.7321 82.4504 108.489 82.4504C109.638 82.4504 110.57 81.5184 110.57 80.3701C110.57 79.2218 109.638 78.2898 108.489 78.2898C101.025 78.2898 94.9539 72.2195 94.9539 64.7555C94.9539 57.2914 101.025 51.2211 108.489 51.2211Z"
                        fill="#061041"
                      />
                      <path
                        d="M139.834 18.5357C149.683 22.9126 158.625 33.78 160.631 43.8112C160.83 44.8014 161.696 45.4837 162.669 45.4837C162.807 45.4837 162.944 45.4713 163.081 45.4421C164.209 45.2175 164.937 44.1191 164.712 42.9957C162.428 31.5749 152.675 19.6882 141.528 14.7329C140.475 14.2669 139.247 14.7412 138.781 15.7897C138.315 16.8382 138.786 18.0697 139.838 18.5357H139.834Z"
                        fill="#061041"
                      />
                      <path
                        d="M145.824 4.07738C155.664 6.98978 176.248 29.8813 178.133 45.3544C178.262 46.4112 179.161 47.1851 180.197 47.1851C180.28 47.1851 180.368 47.1809 180.451 47.1685C181.591 47.0312 182.402 45.991 182.265 44.851C180.114 27.1852 158.09 3.36592 147.005 0.0873852C145.903 -0.23714 144.746 0.391107 144.421 1.49366C144.097 2.59621 144.725 3.75285 145.828 4.07738H145.824Z"
                        fill="#061041"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3480_4927">
                        <rect width="215" height="259" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  {t("Quality")}
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <div className="row row-cols-1 row-cols-md-3 g-2">
              <div className="cols">
                <div
                  className="card mb-3"
                  style={{
                    border: "none",
                    marginRight: "2%",
                    marginLeft: urlPage === "en" ? "2.5%" : "",
                  }}
                >
                  <div className="row g-0">
                    <div className="col-md-4">
                      <svg
                        width="76"
                        height="76"
                        viewBox="0 0 76 76"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "10%",
                        }}
                      >
                        <rect width="76" height="76" rx="38" fill="#142B7B" />
                        <path
                          d="M45.0226 35.4178C45.0857 35.3321 45.136 35.2371 45.1719 35.136C45.0929 35.136 44.9787 34.9996 44.3904 34.9996C43.9884 34.9976 43.5866 35.0219 43.1875 35.0724C43.7319 35.5087 43.8636 35.7269 44.2148 35.7269C44.3632 35.7409 44.5127 35.7204 44.6525 35.6669C44.7922 35.6134 44.9187 35.5283 45.0226 35.4178Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.3637 40.5084C32.4603 40.5084 33.0398 40.1993 34.1198 38.1357C32.6973 38.963 32.1178 39.6448 32.0739 40.0266C32.0651 40.0902 32.0476 40.263 32.3637 40.5084Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M52.9879 44.8994V27.6271C53.0136 27.4057 52.994 27.1813 52.9304 26.9683C52.8669 26.7553 52.7609 26.5586 52.6192 26.3907L44.8659 18.3818C44.633 18.1401 44.3176 18.003 43.9878 18H24.653C24.4149 18.0145 24.1822 18.0791 23.969 18.1897C23.7558 18.3004 23.5666 18.4548 23.413 18.6437C23.2594 18.8325 23.1445 19.0518 23.0754 19.2881C23.0063 19.5244 22.9844 19.7728 23.011 20.0181V44.8994H52.9879ZM43.663 20.3545C43.6614 20.2832 43.6807 20.2132 43.7182 20.1534C43.7557 20.0937 43.8098 20.047 43.8733 20.0195C43.9368 19.9921 44.0068 19.985 44.0742 19.9994C44.1417 20.0137 44.2034 20.0488 44.2513 20.1L50.9684 27.0907C51.0178 27.1403 51.0517 27.2042 51.0655 27.274C51.0794 27.3438 51.0726 27.4163 51.0461 27.4821C51.0195 27.5478 50.9745 27.6038 50.9168 27.6426C50.859 27.6814 50.7914 27.7014 50.7225 27.6998H43.6981L43.663 20.3545ZM30.975 39.8995C31.0979 38.7814 32.4414 37.5996 34.9614 36.4087C36.0028 34.0144 36.8458 31.5328 37.4814 28.9907C36.8141 27.4907 36.1731 25.5453 36.6034 24.4453C36.661 24.2564 36.7587 24.0832 36.8893 23.9385C37.02 23.7938 37.1803 23.6812 37.3585 23.609C37.5864 23.5309 37.822 23.4791 38.0609 23.4544C38.3946 23.4544 38.6931 23.8999 38.939 24.1817C39.1848 24.4635 39.58 24.9908 38.6931 28.8725C39.5761 30.7707 40.7174 32.528 42.0824 34.0906C42.8205 33.9415 43.5697 33.8594 44.3215 33.8451C45.3664 33.8451 45.9986 34.0997 46.2532 34.6178C46.3533 34.8661 46.3816 35.1391 46.3349 35.4037C46.2881 35.6683 46.1683 35.9132 45.9898 36.1087C45.8291 36.3614 45.61 36.5685 45.3524 36.7114C45.0948 36.8543 44.8069 36.9283 44.5147 36.9269C43.4237 36.7843 42.4241 36.2231 41.7137 35.3542C39.6965 35.8041 37.7263 36.4556 35.8307 37.2996C35.3829 38.3207 34.8512 39.3 34.2414 40.2268C33.5565 41.2177 32.9682 41.6813 32.3887 41.6813C32.1569 41.6794 31.932 41.5996 31.7477 41.4541C31.4915 41.2955 31.2834 41.0658 31.1468 40.7911C31.0103 40.5164 30.9507 40.2075 30.975 39.8995Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.8175 49.6265C32.7132 49.4837 32.5778 49.3685 32.4224 49.2901C32.2106 49.1901 31.9789 49.1433 31.7463 49.1538H30.9297V51.881H31.9307C32.0654 51.88 32.199 51.8554 32.3258 51.8083C32.46 51.7598 32.5828 51.6824 32.6858 51.581C32.8058 51.4557 32.896 51.3032 32.9492 51.1355C33.0201 50.9066 33.0527 50.6668 33.0458 50.4265C33.0442 50.295 33.0294 50.164 33.0019 50.0356C32.9677 49.8877 32.905 49.7486 32.8175 49.6265Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M38.2249 30.5361C37.7342 32.3081 37.1479 34.0502 36.4688 35.7542C37.8709 35.1904 39.3084 34.7257 40.7712 34.3633C39.8031 33.1773 38.9502 31.8954 38.2249 30.5361Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M23.0078 46.272V56.6172C23.0752 57.0135 23.2783 57.3713 23.5799 57.6251C23.8815 57.8789 24.2613 58.0117 24.6498 57.999H51.3428C51.7312 58.0117 52.1111 57.8789 52.4127 57.6251C52.7142 57.3713 52.9174 57.0135 52.9848 56.6172V46.272H23.0078ZM33.9309 51.3991C33.8228 51.6724 33.6579 51.9176 33.4479 52.1173C33.2366 52.3062 32.9922 52.4513 32.7279 52.5446C32.4174 52.6559 32.0907 52.7113 31.7621 52.7082H30.9542V55.2718H29.8303V48.2901H31.7621C32.0605 48.2883 32.3572 48.3374 32.6401 48.4356C32.9156 48.5273 33.1719 48.6723 33.3953 48.8628C33.6159 49.0508 33.798 49.2827 33.9309 49.5446C34.0705 49.8254 34.1399 50.138 34.1328 50.4537C34.1266 50.7796 34.0579 51.1009 33.9309 51.3991ZM40.6305 53.0355C40.5328 53.3967 40.3818 53.7402 40.1827 54.0536C40.0194 54.3074 39.8108 54.5265 39.568 54.6991C39.3631 54.8444 39.142 54.9634 38.9095 55.0536C38.7223 55.1269 38.528 55.1788 38.3299 55.2082H37.9348H35.3621V48.2901H37.3816C37.8924 48.2802 38.3999 48.3761 38.8743 48.5719C39.2742 48.738 39.6361 48.9888 39.9368 49.3083C40.2375 49.605 40.474 49.9644 40.6305 50.3628C40.7694 50.7497 40.8407 51.1591 40.8412 51.5719C40.8399 52.0677 40.7689 52.5608 40.6305 53.0355ZM46.5925 49.1446H43.5017V51.3355H46.3027V52.1082H43.5017V55.2718H42.3866V48.2901H46.5837L46.5925 49.1446Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M39.18 49.9177C38.965 49.6721 38.6974 49.4822 38.3985 49.3632C37.9912 49.2089 37.5588 49.1379 37.1254 49.1541H36.4844V54.4176H37.5644C37.8706 54.4464 38.1788 54.3939 38.46 54.2651C38.7411 54.1362 38.9859 53.9353 39.1712 53.6813C39.5369 53.0391 39.7082 52.2981 39.6629 51.5541C39.6635 51.2663 39.6311 50.9794 39.5664 50.6995C39.5012 50.4102 39.3686 50.1417 39.18 49.9177Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M37.8199 24.6998C37.5628 25.0117 37.4268 25.4113 37.4382 25.8211C37.4495 26.2309 37.6074 26.6218 37.8813 26.9179C38.5135 25.4543 37.8462 24.6907 37.8199 24.6998Z"
                          fill="#F5F5F5"
                        />
                      </svg>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5
                          className="card-titleQ"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            overflow: "hidden",
                          }}
                        >
                          {t(
                            "The Faculty of Computing and the E-Learning University are both accredited."
                          )}
                        </h5>

                        <div
                          className="container"
                          style={{
                            textAlign: urlPage === "en" ? "right" : "left",
                          }}
                        >
                          <Link
                            to={pdf1}
                            style={{ textDecoration: "none" }}
                            target="_blank"
                          >
                            <button className="btn-info" style={{}}>
                              {t("More")}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cols">
                <div
                  className="card mb-3"
                  style={{
                    border: "none",
                    marginRight: "2%",
                    height: "88%",
                    marginLeft: urlPage === "en" ? "2.5%" : "",
                  }}
                >
                  <div className="row g-0">
                    <div className="col-md-4">
                      <svg
                        width="76"
                        height="76"
                        viewBox="0 0 76 76"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "10%",
                        }}
                      >
                        <rect width="76" height="76" rx="38" fill="#142B7B" />
                        <path
                          d="M45.0226 35.4178C45.0857 35.3321 45.136 35.2371 45.1719 35.136C45.0929 35.136 44.9787 34.9996 44.3904 34.9996C43.9884 34.9976 43.5866 35.0219 43.1875 35.0724C43.7319 35.5087 43.8636 35.7269 44.2148 35.7269C44.3632 35.7409 44.5127 35.7204 44.6525 35.6669C44.7922 35.6134 44.9187 35.5283 45.0226 35.4178Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.3637 40.5084C32.4603 40.5084 33.0398 40.1993 34.1198 38.1357C32.6973 38.963 32.1178 39.6448 32.0739 40.0266C32.0651 40.0902 32.0476 40.263 32.3637 40.5084Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M52.9879 44.8994V27.6271C53.0136 27.4057 52.994 27.1813 52.9304 26.9683C52.8669 26.7553 52.7609 26.5586 52.6192 26.3907L44.8659 18.3818C44.633 18.1401 44.3176 18.003 43.9878 18H24.653C24.4149 18.0145 24.1822 18.0791 23.969 18.1897C23.7558 18.3004 23.5666 18.4548 23.413 18.6437C23.2594 18.8325 23.1445 19.0518 23.0754 19.2881C23.0063 19.5244 22.9844 19.7728 23.011 20.0181V44.8994H52.9879ZM43.663 20.3545C43.6614 20.2832 43.6807 20.2132 43.7182 20.1534C43.7557 20.0937 43.8098 20.047 43.8733 20.0195C43.9368 19.9921 44.0068 19.985 44.0742 19.9994C44.1417 20.0137 44.2034 20.0488 44.2513 20.1L50.9684 27.0907C51.0178 27.1403 51.0517 27.2042 51.0655 27.274C51.0794 27.3438 51.0726 27.4163 51.0461 27.4821C51.0195 27.5478 50.9745 27.6038 50.9168 27.6426C50.859 27.6814 50.7914 27.7014 50.7225 27.6998H43.6981L43.663 20.3545ZM30.975 39.8995C31.0979 38.7814 32.4414 37.5996 34.9614 36.4087C36.0028 34.0144 36.8458 31.5328 37.4814 28.9907C36.8141 27.4907 36.1731 25.5453 36.6034 24.4453C36.661 24.2564 36.7587 24.0832 36.8893 23.9385C37.02 23.7938 37.1803 23.6812 37.3585 23.609C37.5864 23.5309 37.822 23.4791 38.0609 23.4544C38.3946 23.4544 38.6931 23.8999 38.939 24.1817C39.1848 24.4635 39.58 24.9908 38.6931 28.8725C39.5761 30.7707 40.7174 32.528 42.0824 34.0906C42.8205 33.9415 43.5697 33.8594 44.3215 33.8451C45.3664 33.8451 45.9986 34.0997 46.2532 34.6178C46.3533 34.8661 46.3816 35.1391 46.3349 35.4037C46.2881 35.6683 46.1683 35.9132 45.9898 36.1087C45.8291 36.3614 45.61 36.5685 45.3524 36.7114C45.0948 36.8543 44.8069 36.9283 44.5147 36.9269C43.4237 36.7843 42.4241 36.2231 41.7137 35.3542C39.6965 35.8041 37.7263 36.4556 35.8307 37.2996C35.3829 38.3207 34.8512 39.3 34.2414 40.2268C33.5565 41.2177 32.9682 41.6813 32.3887 41.6813C32.1569 41.6794 31.932 41.5996 31.7477 41.4541C31.4915 41.2955 31.2834 41.0658 31.1468 40.7911C31.0103 40.5164 30.9507 40.2075 30.975 39.8995Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.8175 49.6265C32.7132 49.4837 32.5778 49.3685 32.4224 49.2901C32.2106 49.1901 31.9789 49.1433 31.7463 49.1538H30.9297V51.881H31.9307C32.0654 51.88 32.199 51.8554 32.3258 51.8083C32.46 51.7598 32.5828 51.6824 32.6858 51.581C32.8058 51.4557 32.896 51.3032 32.9492 51.1355C33.0201 50.9066 33.0527 50.6668 33.0458 50.4265C33.0442 50.295 33.0294 50.164 33.0019 50.0356C32.9677 49.8877 32.905 49.7486 32.8175 49.6265Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M38.2249 30.5361C37.7342 32.3081 37.1479 34.0502 36.4688 35.7542C37.8709 35.1904 39.3084 34.7257 40.7712 34.3633C39.8031 33.1773 38.9502 31.8954 38.2249 30.5361Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M23.0078 46.272V56.6172C23.0752 57.0135 23.2783 57.3713 23.5799 57.6251C23.8815 57.8789 24.2613 58.0117 24.6498 57.999H51.3428C51.7312 58.0117 52.1111 57.8789 52.4127 57.6251C52.7142 57.3713 52.9174 57.0135 52.9848 56.6172V46.272H23.0078ZM33.9309 51.3991C33.8228 51.6724 33.6579 51.9176 33.4479 52.1173C33.2366 52.3062 32.9922 52.4513 32.7279 52.5446C32.4174 52.6559 32.0907 52.7113 31.7621 52.7082H30.9542V55.2718H29.8303V48.2901H31.7621C32.0605 48.2883 32.3572 48.3374 32.6401 48.4356C32.9156 48.5273 33.1719 48.6723 33.3953 48.8628C33.6159 49.0508 33.798 49.2827 33.9309 49.5446C34.0705 49.8254 34.1399 50.138 34.1328 50.4537C34.1266 50.7796 34.0579 51.1009 33.9309 51.3991ZM40.6305 53.0355C40.5328 53.3967 40.3818 53.7402 40.1827 54.0536C40.0194 54.3074 39.8108 54.5265 39.568 54.6991C39.3631 54.8444 39.142 54.9634 38.9095 55.0536C38.7223 55.1269 38.528 55.1788 38.3299 55.2082H37.9348H35.3621V48.2901H37.3816C37.8924 48.2802 38.3999 48.3761 38.8743 48.5719C39.2742 48.738 39.6361 48.9888 39.9368 49.3083C40.2375 49.605 40.474 49.9644 40.6305 50.3628C40.7694 50.7497 40.8407 51.1591 40.8412 51.5719C40.8399 52.0677 40.7689 52.5608 40.6305 53.0355ZM46.5925 49.1446H43.5017V51.3355H46.3027V52.1082H43.5017V55.2718H42.3866V48.2901H46.5837L46.5925 49.1446Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M39.18 49.9177C38.965 49.6721 38.6974 49.4822 38.3985 49.3632C37.9912 49.2089 37.5588 49.1379 37.1254 49.1541H36.4844V54.4176H37.5644C37.8706 54.4464 38.1788 54.3939 38.46 54.2651C38.7411 54.1362 38.9859 53.9353 39.1712 53.6813C39.5369 53.0391 39.7082 52.2981 39.6629 51.5541C39.6635 51.2663 39.6311 50.9794 39.5664 50.6995C39.5012 50.4102 39.3686 50.1417 39.18 49.9177Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M37.8199 24.6998C37.5628 25.0117 37.4268 25.4113 37.4382 25.8211C37.4495 26.2309 37.6074 26.6218 37.8813 26.9179C38.5135 25.4543 37.8462 24.6907 37.8199 24.6998Z"
                          fill="#F5F5F5"
                        />
                      </svg>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5
                          className="card-titleQ"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            overflow: "hidden",
                          }}
                        >
                          {t("Student Guide for the Faculty of Computing.")}
                        </h5>

                        <div
                          className="container"
                          style={{
                            textAlign: urlPage === "en" ? "right" : "left",
                          }}
                        >
                          <Link
                            to={pdf2}
                            style={{ textDecoration: "none" }}
                            target="_blank"
                          >
                            <button className="btn-info" style={{}}>
                              {t("More")}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cols">
                <div
                  className="card mb-3"
                  style={{
                    border: "none",
                    marginRight: "2%",
                    marginLeft: urlPage === "en" ? "2.5%" : "",
                  }}
                >
                  <div className="row g-0">
                    <div className="col-md-4">
                      <svg
                        width="76"
                        height="76"
                        viewBox="0 0 76 76"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "10%",
                        }}
                      >
                        <rect width="76" height="76" rx="38" fill="#142B7B" />
                        <path
                          d="M45.0226 35.4178C45.0857 35.3321 45.136 35.2371 45.1719 35.136C45.0929 35.136 44.9787 34.9996 44.3904 34.9996C43.9884 34.9976 43.5866 35.0219 43.1875 35.0724C43.7319 35.5087 43.8636 35.7269 44.2148 35.7269C44.3632 35.7409 44.5127 35.7204 44.6525 35.6669C44.7922 35.6134 44.9187 35.5283 45.0226 35.4178Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.3637 40.5084C32.4603 40.5084 33.0398 40.1993 34.1198 38.1357C32.6973 38.963 32.1178 39.6448 32.0739 40.0266C32.0651 40.0902 32.0476 40.263 32.3637 40.5084Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M52.9879 44.8994V27.6271C53.0136 27.4057 52.994 27.1813 52.9304 26.9683C52.8669 26.7553 52.7609 26.5586 52.6192 26.3907L44.8659 18.3818C44.633 18.1401 44.3176 18.003 43.9878 18H24.653C24.4149 18.0145 24.1822 18.0791 23.969 18.1897C23.7558 18.3004 23.5666 18.4548 23.413 18.6437C23.2594 18.8325 23.1445 19.0518 23.0754 19.2881C23.0063 19.5244 22.9844 19.7728 23.011 20.0181V44.8994H52.9879ZM43.663 20.3545C43.6614 20.2832 43.6807 20.2132 43.7182 20.1534C43.7557 20.0937 43.8098 20.047 43.8733 20.0195C43.9368 19.9921 44.0068 19.985 44.0742 19.9994C44.1417 20.0137 44.2034 20.0488 44.2513 20.1L50.9684 27.0907C51.0178 27.1403 51.0517 27.2042 51.0655 27.274C51.0794 27.3438 51.0726 27.4163 51.0461 27.4821C51.0195 27.5478 50.9745 27.6038 50.9168 27.6426C50.859 27.6814 50.7914 27.7014 50.7225 27.6998H43.6981L43.663 20.3545ZM30.975 39.8995C31.0979 38.7814 32.4414 37.5996 34.9614 36.4087C36.0028 34.0144 36.8458 31.5328 37.4814 28.9907C36.8141 27.4907 36.1731 25.5453 36.6034 24.4453C36.661 24.2564 36.7587 24.0832 36.8893 23.9385C37.02 23.7938 37.1803 23.6812 37.3585 23.609C37.5864 23.5309 37.822 23.4791 38.0609 23.4544C38.3946 23.4544 38.6931 23.8999 38.939 24.1817C39.1848 24.4635 39.58 24.9908 38.6931 28.8725C39.5761 30.7707 40.7174 32.528 42.0824 34.0906C42.8205 33.9415 43.5697 33.8594 44.3215 33.8451C45.3664 33.8451 45.9986 34.0997 46.2532 34.6178C46.3533 34.8661 46.3816 35.1391 46.3349 35.4037C46.2881 35.6683 46.1683 35.9132 45.9898 36.1087C45.8291 36.3614 45.61 36.5685 45.3524 36.7114C45.0948 36.8543 44.8069 36.9283 44.5147 36.9269C43.4237 36.7843 42.4241 36.2231 41.7137 35.3542C39.6965 35.8041 37.7263 36.4556 35.8307 37.2996C35.3829 38.3207 34.8512 39.3 34.2414 40.2268C33.5565 41.2177 32.9682 41.6813 32.3887 41.6813C32.1569 41.6794 31.932 41.5996 31.7477 41.4541C31.4915 41.2955 31.2834 41.0658 31.1468 40.7911C31.0103 40.5164 30.9507 40.2075 30.975 39.8995Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.8175 49.6265C32.7132 49.4837 32.5778 49.3685 32.4224 49.2901C32.2106 49.1901 31.9789 49.1433 31.7463 49.1538H30.9297V51.881H31.9307C32.0654 51.88 32.199 51.8554 32.3258 51.8083C32.46 51.7598 32.5828 51.6824 32.6858 51.581C32.8058 51.4557 32.896 51.3032 32.9492 51.1355C33.0201 50.9066 33.0527 50.6668 33.0458 50.4265C33.0442 50.295 33.0294 50.164 33.0019 50.0356C32.9677 49.8877 32.905 49.7486 32.8175 49.6265Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M38.2249 30.5361C37.7342 32.3081 37.1479 34.0502 36.4688 35.7542C37.8709 35.1904 39.3084 34.7257 40.7712 34.3633C39.8031 33.1773 38.9502 31.8954 38.2249 30.5361Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M23.0078 46.272V56.6172C23.0752 57.0135 23.2783 57.3713 23.5799 57.6251C23.8815 57.8789 24.2613 58.0117 24.6498 57.999H51.3428C51.7312 58.0117 52.1111 57.8789 52.4127 57.6251C52.7142 57.3713 52.9174 57.0135 52.9848 56.6172V46.272H23.0078ZM33.9309 51.3991C33.8228 51.6724 33.6579 51.9176 33.4479 52.1173C33.2366 52.3062 32.9922 52.4513 32.7279 52.5446C32.4174 52.6559 32.0907 52.7113 31.7621 52.7082H30.9542V55.2718H29.8303V48.2901H31.7621C32.0605 48.2883 32.3572 48.3374 32.6401 48.4356C32.9156 48.5273 33.1719 48.6723 33.3953 48.8628C33.6159 49.0508 33.798 49.2827 33.9309 49.5446C34.0705 49.8254 34.1399 50.138 34.1328 50.4537C34.1266 50.7796 34.0579 51.1009 33.9309 51.3991ZM40.6305 53.0355C40.5328 53.3967 40.3818 53.7402 40.1827 54.0536C40.0194 54.3074 39.8108 54.5265 39.568 54.6991C39.3631 54.8444 39.142 54.9634 38.9095 55.0536C38.7223 55.1269 38.528 55.1788 38.3299 55.2082H37.9348H35.3621V48.2901H37.3816C37.8924 48.2802 38.3999 48.3761 38.8743 48.5719C39.2742 48.738 39.6361 48.9888 39.9368 49.3083C40.2375 49.605 40.474 49.9644 40.6305 50.3628C40.7694 50.7497 40.8407 51.1591 40.8412 51.5719C40.8399 52.0677 40.7689 52.5608 40.6305 53.0355ZM46.5925 49.1446H43.5017V51.3355H46.3027V52.1082H43.5017V55.2718H42.3866V48.2901H46.5837L46.5925 49.1446Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M39.18 49.9177C38.965 49.6721 38.6974 49.4822 38.3985 49.3632C37.9912 49.2089 37.5588 49.1379 37.1254 49.1541H36.4844V54.4176H37.5644C37.8706 54.4464 38.1788 54.3939 38.46 54.2651C38.7411 54.1362 38.9859 53.9353 39.1712 53.6813C39.5369 53.0391 39.7082 52.2981 39.6629 51.5541C39.6635 51.2663 39.6311 50.9794 39.5664 50.6995C39.5012 50.4102 39.3686 50.1417 39.18 49.9177Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M37.8199 24.6998C37.5628 25.0117 37.4268 25.4113 37.4382 25.8211C37.4495 26.2309 37.6074 26.6218 37.8813 26.9179C38.5135 25.4543 37.8462 24.6907 37.8199 24.6998Z"
                          fill="#F5F5F5"
                        />
                      </svg>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5
                          className="card-titleQ"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            overflow: "hidden",
                            marginLeft: urlPage === "en" ? "-10%" : "",
                          }}
                        >
                          {t(
                            "Quality Assurance Unit Regulations for the Faculty of Computing at the Egyptian University."
                          )}
                        </h5>

                        <div
                          className="container"
                          style={{
                            textAlign: urlPage === "en" ? "right" : "left",
                          }}
                        >
                          <Link
                            to={pdf3}
                            style={{ textDecoration: "none" }}
                            target="_blank"
                          >
                            <button className="btn-info" style={{}}>
                              {t("More")}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cols">
                <div
                  className="card mb-3"
                  style={{
                    border: "none",
                    marginRight: "2%",
                    marginLeft: urlPage === "en" ? "2.5%" : "",
                  }}
                >
                  <div className="row g-0">
                    <div className="col-md-4">
                      <svg
                        width="76"
                        height="76"
                        viewBox="0 0 76 76"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "10%",
                        }}
                      >
                        <rect width="76" height="76" rx="38" fill="#142B7B" />
                        <path
                          d="M45.0226 35.4178C45.0857 35.3321 45.136 35.2371 45.1719 35.136C45.0929 35.136 44.9787 34.9996 44.3904 34.9996C43.9884 34.9976 43.5866 35.0219 43.1875 35.0724C43.7319 35.5087 43.8636 35.7269 44.2148 35.7269C44.3632 35.7409 44.5127 35.7204 44.6525 35.6669C44.7922 35.6134 44.9187 35.5283 45.0226 35.4178Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.3637 40.5084C32.4603 40.5084 33.0398 40.1993 34.1198 38.1357C32.6973 38.963 32.1178 39.6448 32.0739 40.0266C32.0651 40.0902 32.0476 40.263 32.3637 40.5084Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M52.9879 44.8994V27.6271C53.0136 27.4057 52.994 27.1813 52.9304 26.9683C52.8669 26.7553 52.7609 26.5586 52.6192 26.3907L44.8659 18.3818C44.633 18.1401 44.3176 18.003 43.9878 18H24.653C24.4149 18.0145 24.1822 18.0791 23.969 18.1897C23.7558 18.3004 23.5666 18.4548 23.413 18.6437C23.2594 18.8325 23.1445 19.0518 23.0754 19.2881C23.0063 19.5244 22.9844 19.7728 23.011 20.0181V44.8994H52.9879ZM43.663 20.3545C43.6614 20.2832 43.6807 20.2132 43.7182 20.1534C43.7557 20.0937 43.8098 20.047 43.8733 20.0195C43.9368 19.9921 44.0068 19.985 44.0742 19.9994C44.1417 20.0137 44.2034 20.0488 44.2513 20.1L50.9684 27.0907C51.0178 27.1403 51.0517 27.2042 51.0655 27.274C51.0794 27.3438 51.0726 27.4163 51.0461 27.4821C51.0195 27.5478 50.9745 27.6038 50.9168 27.6426C50.859 27.6814 50.7914 27.7014 50.7225 27.6998H43.6981L43.663 20.3545ZM30.975 39.8995C31.0979 38.7814 32.4414 37.5996 34.9614 36.4087C36.0028 34.0144 36.8458 31.5328 37.4814 28.9907C36.8141 27.4907 36.1731 25.5453 36.6034 24.4453C36.661 24.2564 36.7587 24.0832 36.8893 23.9385C37.02 23.7938 37.1803 23.6812 37.3585 23.609C37.5864 23.5309 37.822 23.4791 38.0609 23.4544C38.3946 23.4544 38.6931 23.8999 38.939 24.1817C39.1848 24.4635 39.58 24.9908 38.6931 28.8725C39.5761 30.7707 40.7174 32.528 42.0824 34.0906C42.8205 33.9415 43.5697 33.8594 44.3215 33.8451C45.3664 33.8451 45.9986 34.0997 46.2532 34.6178C46.3533 34.8661 46.3816 35.1391 46.3349 35.4037C46.2881 35.6683 46.1683 35.9132 45.9898 36.1087C45.8291 36.3614 45.61 36.5685 45.3524 36.7114C45.0948 36.8543 44.8069 36.9283 44.5147 36.9269C43.4237 36.7843 42.4241 36.2231 41.7137 35.3542C39.6965 35.8041 37.7263 36.4556 35.8307 37.2996C35.3829 38.3207 34.8512 39.3 34.2414 40.2268C33.5565 41.2177 32.9682 41.6813 32.3887 41.6813C32.1569 41.6794 31.932 41.5996 31.7477 41.4541C31.4915 41.2955 31.2834 41.0658 31.1468 40.7911C31.0103 40.5164 30.9507 40.2075 30.975 39.8995Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.8175 49.6265C32.7132 49.4837 32.5778 49.3685 32.4224 49.2901C32.2106 49.1901 31.9789 49.1433 31.7463 49.1538H30.9297V51.881H31.9307C32.0654 51.88 32.199 51.8554 32.3258 51.8083C32.46 51.7598 32.5828 51.6824 32.6858 51.581C32.8058 51.4557 32.896 51.3032 32.9492 51.1355C33.0201 50.9066 33.0527 50.6668 33.0458 50.4265C33.0442 50.295 33.0294 50.164 33.0019 50.0356C32.9677 49.8877 32.905 49.7486 32.8175 49.6265Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M38.2249 30.5361C37.7342 32.3081 37.1479 34.0502 36.4688 35.7542C37.8709 35.1904 39.3084 34.7257 40.7712 34.3633C39.8031 33.1773 38.9502 31.8954 38.2249 30.5361Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M23.0078 46.272V56.6172C23.0752 57.0135 23.2783 57.3713 23.5799 57.6251C23.8815 57.8789 24.2613 58.0117 24.6498 57.999H51.3428C51.7312 58.0117 52.1111 57.8789 52.4127 57.6251C52.7142 57.3713 52.9174 57.0135 52.9848 56.6172V46.272H23.0078ZM33.9309 51.3991C33.8228 51.6724 33.6579 51.9176 33.4479 52.1173C33.2366 52.3062 32.9922 52.4513 32.7279 52.5446C32.4174 52.6559 32.0907 52.7113 31.7621 52.7082H30.9542V55.2718H29.8303V48.2901H31.7621C32.0605 48.2883 32.3572 48.3374 32.6401 48.4356C32.9156 48.5273 33.1719 48.6723 33.3953 48.8628C33.6159 49.0508 33.798 49.2827 33.9309 49.5446C34.0705 49.8254 34.1399 50.138 34.1328 50.4537C34.1266 50.7796 34.0579 51.1009 33.9309 51.3991ZM40.6305 53.0355C40.5328 53.3967 40.3818 53.7402 40.1827 54.0536C40.0194 54.3074 39.8108 54.5265 39.568 54.6991C39.3631 54.8444 39.142 54.9634 38.9095 55.0536C38.7223 55.1269 38.528 55.1788 38.3299 55.2082H37.9348H35.3621V48.2901H37.3816C37.8924 48.2802 38.3999 48.3761 38.8743 48.5719C39.2742 48.738 39.6361 48.9888 39.9368 49.3083C40.2375 49.605 40.474 49.9644 40.6305 50.3628C40.7694 50.7497 40.8407 51.1591 40.8412 51.5719C40.8399 52.0677 40.7689 52.5608 40.6305 53.0355ZM46.5925 49.1446H43.5017V51.3355H46.3027V52.1082H43.5017V55.2718H42.3866V48.2901H46.5837L46.5925 49.1446Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M39.18 49.9177C38.965 49.6721 38.6974 49.4822 38.3985 49.3632C37.9912 49.2089 37.5588 49.1379 37.1254 49.1541H36.4844V54.4176H37.5644C37.8706 54.4464 38.1788 54.3939 38.46 54.2651C38.7411 54.1362 38.9859 53.9353 39.1712 53.6813C39.5369 53.0391 39.7082 52.2981 39.6629 51.5541C39.6635 51.2663 39.6311 50.9794 39.5664 50.6995C39.5012 50.4102 39.3686 50.1417 39.18 49.9177Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M37.8199 24.6998C37.5628 25.0117 37.4268 25.4113 37.4382 25.8211C37.4495 26.2309 37.6074 26.6218 37.8813 26.9179C38.5135 25.4543 37.8462 24.6907 37.8199 24.6998Z"
                          fill="#F5F5F5"
                        />
                      </svg>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5
                          className="card-titleQ"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            overflow: "hidden",
                          }}
                        >
                          {t("Self-Study")}
                        </h5>

                        <div
                          className="container"
                          style={{
                            textAlign: urlPage === "en" ? "right" : "left",
                          }}
                        >
                          <Link
                            to={pdf4}
                            style={{ textDecoration: "none" }}
                            target="_blank"
                          >
                            <button className="btn-info" style={{}}>
                              {t("More")}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cols">
                <div
                  className="card mb-3"
                  style={{
                    border: "none",
                    marginRight: "2%",
                    marginLeft: urlPage === "en" ? "2.5%" : "",
                  }}
                >
                  <div className="row g-0">
                    <div className="col-md-4">
                      <svg
                        width="76"
                        height="76"
                        viewBox="0 0 76 76"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "10%",
                        }}
                      >
                        <rect width="76" height="76" rx="38" fill="#142B7B" />
                        <path
                          d="M45.0226 35.4178C45.0857 35.3321 45.136 35.2371 45.1719 35.136C45.0929 35.136 44.9787 34.9996 44.3904 34.9996C43.9884 34.9976 43.5866 35.0219 43.1875 35.0724C43.7319 35.5087 43.8636 35.7269 44.2148 35.7269C44.3632 35.7409 44.5127 35.7204 44.6525 35.6669C44.7922 35.6134 44.9187 35.5283 45.0226 35.4178Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.3637 40.5084C32.4603 40.5084 33.0398 40.1993 34.1198 38.1357C32.6973 38.963 32.1178 39.6448 32.0739 40.0266C32.0651 40.0902 32.0476 40.263 32.3637 40.5084Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M52.9879 44.8994V27.6271C53.0136 27.4057 52.994 27.1813 52.9304 26.9683C52.8669 26.7553 52.7609 26.5586 52.6192 26.3907L44.8659 18.3818C44.633 18.1401 44.3176 18.003 43.9878 18H24.653C24.4149 18.0145 24.1822 18.0791 23.969 18.1897C23.7558 18.3004 23.5666 18.4548 23.413 18.6437C23.2594 18.8325 23.1445 19.0518 23.0754 19.2881C23.0063 19.5244 22.9844 19.7728 23.011 20.0181V44.8994H52.9879ZM43.663 20.3545C43.6614 20.2832 43.6807 20.2132 43.7182 20.1534C43.7557 20.0937 43.8098 20.047 43.8733 20.0195C43.9368 19.9921 44.0068 19.985 44.0742 19.9994C44.1417 20.0137 44.2034 20.0488 44.2513 20.1L50.9684 27.0907C51.0178 27.1403 51.0517 27.2042 51.0655 27.274C51.0794 27.3438 51.0726 27.4163 51.0461 27.4821C51.0195 27.5478 50.9745 27.6038 50.9168 27.6426C50.859 27.6814 50.7914 27.7014 50.7225 27.6998H43.6981L43.663 20.3545ZM30.975 39.8995C31.0979 38.7814 32.4414 37.5996 34.9614 36.4087C36.0028 34.0144 36.8458 31.5328 37.4814 28.9907C36.8141 27.4907 36.1731 25.5453 36.6034 24.4453C36.661 24.2564 36.7587 24.0832 36.8893 23.9385C37.02 23.7938 37.1803 23.6812 37.3585 23.609C37.5864 23.5309 37.822 23.4791 38.0609 23.4544C38.3946 23.4544 38.6931 23.8999 38.939 24.1817C39.1848 24.4635 39.58 24.9908 38.6931 28.8725C39.5761 30.7707 40.7174 32.528 42.0824 34.0906C42.8205 33.9415 43.5697 33.8594 44.3215 33.8451C45.3664 33.8451 45.9986 34.0997 46.2532 34.6178C46.3533 34.8661 46.3816 35.1391 46.3349 35.4037C46.2881 35.6683 46.1683 35.9132 45.9898 36.1087C45.8291 36.3614 45.61 36.5685 45.3524 36.7114C45.0948 36.8543 44.8069 36.9283 44.5147 36.9269C43.4237 36.7843 42.4241 36.2231 41.7137 35.3542C39.6965 35.8041 37.7263 36.4556 35.8307 37.2996C35.3829 38.3207 34.8512 39.3 34.2414 40.2268C33.5565 41.2177 32.9682 41.6813 32.3887 41.6813C32.1569 41.6794 31.932 41.5996 31.7477 41.4541C31.4915 41.2955 31.2834 41.0658 31.1468 40.7911C31.0103 40.5164 30.9507 40.2075 30.975 39.8995Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.8175 49.6265C32.7132 49.4837 32.5778 49.3685 32.4224 49.2901C32.2106 49.1901 31.9789 49.1433 31.7463 49.1538H30.9297V51.881H31.9307C32.0654 51.88 32.199 51.8554 32.3258 51.8083C32.46 51.7598 32.5828 51.6824 32.6858 51.581C32.8058 51.4557 32.896 51.3032 32.9492 51.1355C33.0201 50.9066 33.0527 50.6668 33.0458 50.4265C33.0442 50.295 33.0294 50.164 33.0019 50.0356C32.9677 49.8877 32.905 49.7486 32.8175 49.6265Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M38.2249 30.5361C37.7342 32.3081 37.1479 34.0502 36.4688 35.7542C37.8709 35.1904 39.3084 34.7257 40.7712 34.3633C39.8031 33.1773 38.9502 31.8954 38.2249 30.5361Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M23.0078 46.272V56.6172C23.0752 57.0135 23.2783 57.3713 23.5799 57.6251C23.8815 57.8789 24.2613 58.0117 24.6498 57.999H51.3428C51.7312 58.0117 52.1111 57.8789 52.4127 57.6251C52.7142 57.3713 52.9174 57.0135 52.9848 56.6172V46.272H23.0078ZM33.9309 51.3991C33.8228 51.6724 33.6579 51.9176 33.4479 52.1173C33.2366 52.3062 32.9922 52.4513 32.7279 52.5446C32.4174 52.6559 32.0907 52.7113 31.7621 52.7082H30.9542V55.2718H29.8303V48.2901H31.7621C32.0605 48.2883 32.3572 48.3374 32.6401 48.4356C32.9156 48.5273 33.1719 48.6723 33.3953 48.8628C33.6159 49.0508 33.798 49.2827 33.9309 49.5446C34.0705 49.8254 34.1399 50.138 34.1328 50.4537C34.1266 50.7796 34.0579 51.1009 33.9309 51.3991ZM40.6305 53.0355C40.5328 53.3967 40.3818 53.7402 40.1827 54.0536C40.0194 54.3074 39.8108 54.5265 39.568 54.6991C39.3631 54.8444 39.142 54.9634 38.9095 55.0536C38.7223 55.1269 38.528 55.1788 38.3299 55.2082H37.9348H35.3621V48.2901H37.3816C37.8924 48.2802 38.3999 48.3761 38.8743 48.5719C39.2742 48.738 39.6361 48.9888 39.9368 49.3083C40.2375 49.605 40.474 49.9644 40.6305 50.3628C40.7694 50.7497 40.8407 51.1591 40.8412 51.5719C40.8399 52.0677 40.7689 52.5608 40.6305 53.0355ZM46.5925 49.1446H43.5017V51.3355H46.3027V52.1082H43.5017V55.2718H42.3866V48.2901H46.5837L46.5925 49.1446Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M39.18 49.9177C38.965 49.6721 38.6974 49.4822 38.3985 49.3632C37.9912 49.2089 37.5588 49.1379 37.1254 49.1541H36.4844V54.4176H37.5644C37.8706 54.4464 38.1788 54.3939 38.46 54.2651C38.7411 54.1362 38.9859 53.9353 39.1712 53.6813C39.5369 53.0391 39.7082 52.2981 39.6629 51.5541C39.6635 51.2663 39.6311 50.9794 39.5664 50.6995C39.5012 50.4102 39.3686 50.1417 39.18 49.9177Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M37.8199 24.6998C37.5628 25.0117 37.4268 25.4113 37.4382 25.8211C37.4495 26.2309 37.6074 26.6218 37.8813 26.9179C38.5135 25.4543 37.8462 24.6907 37.8199 24.6998Z"
                          fill="#F5F5F5"
                        />
                      </svg>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5
                          className="card-titleQ"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            overflow: "hidden",
                          }}
                        >
                          {t("Strategic Plan")}
                        </h5>

                        <div
                          className="container"
                          style={{
                            textAlign: urlPage === "en" ? "right" : "left",
                          }}
                        >
                          <Link
                            to={pdf5}
                            style={{ textDecoration: "none" }}
                            target="_blank"
                          >
                            <button className="btn-info" style={{}}>
                              {t("More")}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cols">
                <div
                  className="card mb-3"
                  style={{
                    border: "none",
                    marginRight: "2%",
                    marginLeft: urlPage === "en" ? "2.5%" : "",
                  }}
                >
                  <div className="row g-0">
                    <div className="col-md-4">
                      <svg
                        width="76"
                        height="76"
                        viewBox="0 0 76 76"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "10%",
                        }}
                      >
                        <rect width="76" height="76" rx="38" fill="#142B7B" />
                        <path
                          d="M45.0226 35.4178C45.0857 35.3321 45.136 35.2371 45.1719 35.136C45.0929 35.136 44.9787 34.9996 44.3904 34.9996C43.9884 34.9976 43.5866 35.0219 43.1875 35.0724C43.7319 35.5087 43.8636 35.7269 44.2148 35.7269C44.3632 35.7409 44.5127 35.7204 44.6525 35.6669C44.7922 35.6134 44.9187 35.5283 45.0226 35.4178Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.3637 40.5084C32.4603 40.5084 33.0398 40.1993 34.1198 38.1357C32.6973 38.963 32.1178 39.6448 32.0739 40.0266C32.0651 40.0902 32.0476 40.263 32.3637 40.5084Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M52.9879 44.8994V27.6271C53.0136 27.4057 52.994 27.1813 52.9304 26.9683C52.8669 26.7553 52.7609 26.5586 52.6192 26.3907L44.8659 18.3818C44.633 18.1401 44.3176 18.003 43.9878 18H24.653C24.4149 18.0145 24.1822 18.0791 23.969 18.1897C23.7558 18.3004 23.5666 18.4548 23.413 18.6437C23.2594 18.8325 23.1445 19.0518 23.0754 19.2881C23.0063 19.5244 22.9844 19.7728 23.011 20.0181V44.8994H52.9879ZM43.663 20.3545C43.6614 20.2832 43.6807 20.2132 43.7182 20.1534C43.7557 20.0937 43.8098 20.047 43.8733 20.0195C43.9368 19.9921 44.0068 19.985 44.0742 19.9994C44.1417 20.0137 44.2034 20.0488 44.2513 20.1L50.9684 27.0907C51.0178 27.1403 51.0517 27.2042 51.0655 27.274C51.0794 27.3438 51.0726 27.4163 51.0461 27.4821C51.0195 27.5478 50.9745 27.6038 50.9168 27.6426C50.859 27.6814 50.7914 27.7014 50.7225 27.6998H43.6981L43.663 20.3545ZM30.975 39.8995C31.0979 38.7814 32.4414 37.5996 34.9614 36.4087C36.0028 34.0144 36.8458 31.5328 37.4814 28.9907C36.8141 27.4907 36.1731 25.5453 36.6034 24.4453C36.661 24.2564 36.7587 24.0832 36.8893 23.9385C37.02 23.7938 37.1803 23.6812 37.3585 23.609C37.5864 23.5309 37.822 23.4791 38.0609 23.4544C38.3946 23.4544 38.6931 23.8999 38.939 24.1817C39.1848 24.4635 39.58 24.9908 38.6931 28.8725C39.5761 30.7707 40.7174 32.528 42.0824 34.0906C42.8205 33.9415 43.5697 33.8594 44.3215 33.8451C45.3664 33.8451 45.9986 34.0997 46.2532 34.6178C46.3533 34.8661 46.3816 35.1391 46.3349 35.4037C46.2881 35.6683 46.1683 35.9132 45.9898 36.1087C45.8291 36.3614 45.61 36.5685 45.3524 36.7114C45.0948 36.8543 44.8069 36.9283 44.5147 36.9269C43.4237 36.7843 42.4241 36.2231 41.7137 35.3542C39.6965 35.8041 37.7263 36.4556 35.8307 37.2996C35.3829 38.3207 34.8512 39.3 34.2414 40.2268C33.5565 41.2177 32.9682 41.6813 32.3887 41.6813C32.1569 41.6794 31.932 41.5996 31.7477 41.4541C31.4915 41.2955 31.2834 41.0658 31.1468 40.7911C31.0103 40.5164 30.9507 40.2075 30.975 39.8995Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.8175 49.6265C32.7132 49.4837 32.5778 49.3685 32.4224 49.2901C32.2106 49.1901 31.9789 49.1433 31.7463 49.1538H30.9297V51.881H31.9307C32.0654 51.88 32.199 51.8554 32.3258 51.8083C32.46 51.7598 32.5828 51.6824 32.6858 51.581C32.8058 51.4557 32.896 51.3032 32.9492 51.1355C33.0201 50.9066 33.0527 50.6668 33.0458 50.4265C33.0442 50.295 33.0294 50.164 33.0019 50.0356C32.9677 49.8877 32.905 49.7486 32.8175 49.6265Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M38.2249 30.5361C37.7342 32.3081 37.1479 34.0502 36.4688 35.7542C37.8709 35.1904 39.3084 34.7257 40.7712 34.3633C39.8031 33.1773 38.9502 31.8954 38.2249 30.5361Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M23.0078 46.272V56.6172C23.0752 57.0135 23.2783 57.3713 23.5799 57.6251C23.8815 57.8789 24.2613 58.0117 24.6498 57.999H51.3428C51.7312 58.0117 52.1111 57.8789 52.4127 57.6251C52.7142 57.3713 52.9174 57.0135 52.9848 56.6172V46.272H23.0078ZM33.9309 51.3991C33.8228 51.6724 33.6579 51.9176 33.4479 52.1173C33.2366 52.3062 32.9922 52.4513 32.7279 52.5446C32.4174 52.6559 32.0907 52.7113 31.7621 52.7082H30.9542V55.2718H29.8303V48.2901H31.7621C32.0605 48.2883 32.3572 48.3374 32.6401 48.4356C32.9156 48.5273 33.1719 48.6723 33.3953 48.8628C33.6159 49.0508 33.798 49.2827 33.9309 49.5446C34.0705 49.8254 34.1399 50.138 34.1328 50.4537C34.1266 50.7796 34.0579 51.1009 33.9309 51.3991ZM40.6305 53.0355C40.5328 53.3967 40.3818 53.7402 40.1827 54.0536C40.0194 54.3074 39.8108 54.5265 39.568 54.6991C39.3631 54.8444 39.142 54.9634 38.9095 55.0536C38.7223 55.1269 38.528 55.1788 38.3299 55.2082H37.9348H35.3621V48.2901H37.3816C37.8924 48.2802 38.3999 48.3761 38.8743 48.5719C39.2742 48.738 39.6361 48.9888 39.9368 49.3083C40.2375 49.605 40.474 49.9644 40.6305 50.3628C40.7694 50.7497 40.8407 51.1591 40.8412 51.5719C40.8399 52.0677 40.7689 52.5608 40.6305 53.0355ZM46.5925 49.1446H43.5017V51.3355H46.3027V52.1082H43.5017V55.2718H42.3866V48.2901H46.5837L46.5925 49.1446Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M39.18 49.9177C38.965 49.6721 38.6974 49.4822 38.3985 49.3632C37.9912 49.2089 37.5588 49.1379 37.1254 49.1541H36.4844V54.4176H37.5644C37.8706 54.4464 38.1788 54.3939 38.46 54.2651C38.7411 54.1362 38.9859 53.9353 39.1712 53.6813C39.5369 53.0391 39.7082 52.2981 39.6629 51.5541C39.6635 51.2663 39.6311 50.9794 39.5664 50.6995C39.5012 50.4102 39.3686 50.1417 39.18 49.9177Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M37.8199 24.6998C37.5628 25.0117 37.4268 25.4113 37.4382 25.8211C37.4495 26.2309 37.6074 26.6218 37.8813 26.9179C38.5135 25.4543 37.8462 24.6907 37.8199 24.6998Z"
                          fill="#F5F5F5"
                        />
                      </svg>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5
                          className="card-titleQ"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            overflow: "hidden",
                            marginLeft: urlPage === "en" ? "-18%" : "",
                          }}
                        >
                          {t("Teaching, Learning, and Assessment Strategy")}
                        </h5>

                        <div
                          className="container"
                          style={{
                            textAlign: urlPage === "en" ? "right" : "left",
                          }}
                        >
                          <Link
                            to={pdf6}
                            style={{ textDecoration: "none" }}
                            target="_blank"
                          >
                            <button className="btn-info" style={{}}>
                              {t("More")}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cols">
                <div
                  className="card mb-3"
                  style={{
                    border: "none",
                    marginRight: "2%",
                    marginLeft: urlPage === "en" ? "2.5%" : "",
                  }}
                >
                  <div className="row g-0">
                    <div className="col-md-4">
                      <svg
                        width="76"
                        height="76"
                        viewBox="0 0 76 76"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "10%",
                        }}
                      >
                        <rect width="76" height="76" rx="38" fill="#142B7B" />
                        <path
                          d="M45.0226 35.4178C45.0857 35.3321 45.136 35.2371 45.1719 35.136C45.0929 35.136 44.9787 34.9996 44.3904 34.9996C43.9884 34.9976 43.5866 35.0219 43.1875 35.0724C43.7319 35.5087 43.8636 35.7269 44.2148 35.7269C44.3632 35.7409 44.5127 35.7204 44.6525 35.6669C44.7922 35.6134 44.9187 35.5283 45.0226 35.4178Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.3637 40.5084C32.4603 40.5084 33.0398 40.1993 34.1198 38.1357C32.6973 38.963 32.1178 39.6448 32.0739 40.0266C32.0651 40.0902 32.0476 40.263 32.3637 40.5084Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M52.9879 44.8994V27.6271C53.0136 27.4057 52.994 27.1813 52.9304 26.9683C52.8669 26.7553 52.7609 26.5586 52.6192 26.3907L44.8659 18.3818C44.633 18.1401 44.3176 18.003 43.9878 18H24.653C24.4149 18.0145 24.1822 18.0791 23.969 18.1897C23.7558 18.3004 23.5666 18.4548 23.413 18.6437C23.2594 18.8325 23.1445 19.0518 23.0754 19.2881C23.0063 19.5244 22.9844 19.7728 23.011 20.0181V44.8994H52.9879ZM43.663 20.3545C43.6614 20.2832 43.6807 20.2132 43.7182 20.1534C43.7557 20.0937 43.8098 20.047 43.8733 20.0195C43.9368 19.9921 44.0068 19.985 44.0742 19.9994C44.1417 20.0137 44.2034 20.0488 44.2513 20.1L50.9684 27.0907C51.0178 27.1403 51.0517 27.2042 51.0655 27.274C51.0794 27.3438 51.0726 27.4163 51.0461 27.4821C51.0195 27.5478 50.9745 27.6038 50.9168 27.6426C50.859 27.6814 50.7914 27.7014 50.7225 27.6998H43.6981L43.663 20.3545ZM30.975 39.8995C31.0979 38.7814 32.4414 37.5996 34.9614 36.4087C36.0028 34.0144 36.8458 31.5328 37.4814 28.9907C36.8141 27.4907 36.1731 25.5453 36.6034 24.4453C36.661 24.2564 36.7587 24.0832 36.8893 23.9385C37.02 23.7938 37.1803 23.6812 37.3585 23.609C37.5864 23.5309 37.822 23.4791 38.0609 23.4544C38.3946 23.4544 38.6931 23.8999 38.939 24.1817C39.1848 24.4635 39.58 24.9908 38.6931 28.8725C39.5761 30.7707 40.7174 32.528 42.0824 34.0906C42.8205 33.9415 43.5697 33.8594 44.3215 33.8451C45.3664 33.8451 45.9986 34.0997 46.2532 34.6178C46.3533 34.8661 46.3816 35.1391 46.3349 35.4037C46.2881 35.6683 46.1683 35.9132 45.9898 36.1087C45.8291 36.3614 45.61 36.5685 45.3524 36.7114C45.0948 36.8543 44.8069 36.9283 44.5147 36.9269C43.4237 36.7843 42.4241 36.2231 41.7137 35.3542C39.6965 35.8041 37.7263 36.4556 35.8307 37.2996C35.3829 38.3207 34.8512 39.3 34.2414 40.2268C33.5565 41.2177 32.9682 41.6813 32.3887 41.6813C32.1569 41.6794 31.932 41.5996 31.7477 41.4541C31.4915 41.2955 31.2834 41.0658 31.1468 40.7911C31.0103 40.5164 30.9507 40.2075 30.975 39.8995Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.8175 49.6265C32.7132 49.4837 32.5778 49.3685 32.4224 49.2901C32.2106 49.1901 31.9789 49.1433 31.7463 49.1538H30.9297V51.881H31.9307C32.0654 51.88 32.199 51.8554 32.3258 51.8083C32.46 51.7598 32.5828 51.6824 32.6858 51.581C32.8058 51.4557 32.896 51.3032 32.9492 51.1355C33.0201 50.9066 33.0527 50.6668 33.0458 50.4265C33.0442 50.295 33.0294 50.164 33.0019 50.0356C32.9677 49.8877 32.905 49.7486 32.8175 49.6265Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M38.2249 30.5361C37.7342 32.3081 37.1479 34.0502 36.4688 35.7542C37.8709 35.1904 39.3084 34.7257 40.7712 34.3633C39.8031 33.1773 38.9502 31.8954 38.2249 30.5361Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M23.0078 46.272V56.6172C23.0752 57.0135 23.2783 57.3713 23.5799 57.6251C23.8815 57.8789 24.2613 58.0117 24.6498 57.999H51.3428C51.7312 58.0117 52.1111 57.8789 52.4127 57.6251C52.7142 57.3713 52.9174 57.0135 52.9848 56.6172V46.272H23.0078ZM33.9309 51.3991C33.8228 51.6724 33.6579 51.9176 33.4479 52.1173C33.2366 52.3062 32.9922 52.4513 32.7279 52.5446C32.4174 52.6559 32.0907 52.7113 31.7621 52.7082H30.9542V55.2718H29.8303V48.2901H31.7621C32.0605 48.2883 32.3572 48.3374 32.6401 48.4356C32.9156 48.5273 33.1719 48.6723 33.3953 48.8628C33.6159 49.0508 33.798 49.2827 33.9309 49.5446C34.0705 49.8254 34.1399 50.138 34.1328 50.4537C34.1266 50.7796 34.0579 51.1009 33.9309 51.3991ZM40.6305 53.0355C40.5328 53.3967 40.3818 53.7402 40.1827 54.0536C40.0194 54.3074 39.8108 54.5265 39.568 54.6991C39.3631 54.8444 39.142 54.9634 38.9095 55.0536C38.7223 55.1269 38.528 55.1788 38.3299 55.2082H37.9348H35.3621V48.2901H37.3816C37.8924 48.2802 38.3999 48.3761 38.8743 48.5719C39.2742 48.738 39.6361 48.9888 39.9368 49.3083C40.2375 49.605 40.474 49.9644 40.6305 50.3628C40.7694 50.7497 40.8407 51.1591 40.8412 51.5719C40.8399 52.0677 40.7689 52.5608 40.6305 53.0355ZM46.5925 49.1446H43.5017V51.3355H46.3027V52.1082H43.5017V55.2718H42.3866V48.2901H46.5837L46.5925 49.1446Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M39.18 49.9177C38.965 49.6721 38.6974 49.4822 38.3985 49.3632C37.9912 49.2089 37.5588 49.1379 37.1254 49.1541H36.4844V54.4176H37.5644C37.8706 54.4464 38.1788 54.3939 38.46 54.2651C38.7411 54.1362 38.9859 53.9353 39.1712 53.6813C39.5369 53.0391 39.7082 52.2981 39.6629 51.5541C39.6635 51.2663 39.6311 50.9794 39.5664 50.6995C39.5012 50.4102 39.3686 50.1417 39.18 49.9177Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M37.8199 24.6998C37.5628 25.0117 37.4268 25.4113 37.4382 25.8211C37.4495 26.2309 37.6074 26.6218 37.8813 26.9179C38.5135 25.4543 37.8462 24.6907 37.8199 24.6998Z"
                          fill="#F5F5F5"
                        />
                      </svg>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5
                          className="card-titleQ"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            overflow: "hidden",
                            marginLeft: urlPage === "en" ? "-8%" : "",
                          }}
                        >
                          {t(
                            "Technological Infrastructure of the Faculty of Computing"
                          )}
                        </h5>

                        <div
                          className="container"
                          style={{
                            textAlign: urlPage === "en" ? "right" : "left",
                          }}
                        >
                          <Link
                            to={pdf7}
                            style={{ textDecoration: "none" }}
                            target="_blank"
                          >
                            <button className="btn-info" style={{}}>
                              {t("More")}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cols">
                <div
                  className="card mb-3"
                  style={{
                    border: "none",
                    marginRight: "2%",
                    marginLeft: urlPage === "en" ? "2.5%" : "",
                  }}
                >
                  <div className="row g-0">
                    <div className="col-md-4">
                      <svg
                        width="76"
                        height="76"
                        viewBox="0 0 76 76"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "10%",
                        }}
                      >
                        <rect width="76" height="76" rx="38" fill="#142B7B" />
                        <path
                          d="M45.0226 35.4178C45.0857 35.3321 45.136 35.2371 45.1719 35.136C45.0929 35.136 44.9787 34.9996 44.3904 34.9996C43.9884 34.9976 43.5866 35.0219 43.1875 35.0724C43.7319 35.5087 43.8636 35.7269 44.2148 35.7269C44.3632 35.7409 44.5127 35.7204 44.6525 35.6669C44.7922 35.6134 44.9187 35.5283 45.0226 35.4178Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.3637 40.5084C32.4603 40.5084 33.0398 40.1993 34.1198 38.1357C32.6973 38.963 32.1178 39.6448 32.0739 40.0266C32.0651 40.0902 32.0476 40.263 32.3637 40.5084Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M52.9879 44.8994V27.6271C53.0136 27.4057 52.994 27.1813 52.9304 26.9683C52.8669 26.7553 52.7609 26.5586 52.6192 26.3907L44.8659 18.3818C44.633 18.1401 44.3176 18.003 43.9878 18H24.653C24.4149 18.0145 24.1822 18.0791 23.969 18.1897C23.7558 18.3004 23.5666 18.4548 23.413 18.6437C23.2594 18.8325 23.1445 19.0518 23.0754 19.2881C23.0063 19.5244 22.9844 19.7728 23.011 20.0181V44.8994H52.9879ZM43.663 20.3545C43.6614 20.2832 43.6807 20.2132 43.7182 20.1534C43.7557 20.0937 43.8098 20.047 43.8733 20.0195C43.9368 19.9921 44.0068 19.985 44.0742 19.9994C44.1417 20.0137 44.2034 20.0488 44.2513 20.1L50.9684 27.0907C51.0178 27.1403 51.0517 27.2042 51.0655 27.274C51.0794 27.3438 51.0726 27.4163 51.0461 27.4821C51.0195 27.5478 50.9745 27.6038 50.9168 27.6426C50.859 27.6814 50.7914 27.7014 50.7225 27.6998H43.6981L43.663 20.3545ZM30.975 39.8995C31.0979 38.7814 32.4414 37.5996 34.9614 36.4087C36.0028 34.0144 36.8458 31.5328 37.4814 28.9907C36.8141 27.4907 36.1731 25.5453 36.6034 24.4453C36.661 24.2564 36.7587 24.0832 36.8893 23.9385C37.02 23.7938 37.1803 23.6812 37.3585 23.609C37.5864 23.5309 37.822 23.4791 38.0609 23.4544C38.3946 23.4544 38.6931 23.8999 38.939 24.1817C39.1848 24.4635 39.58 24.9908 38.6931 28.8725C39.5761 30.7707 40.7174 32.528 42.0824 34.0906C42.8205 33.9415 43.5697 33.8594 44.3215 33.8451C45.3664 33.8451 45.9986 34.0997 46.2532 34.6178C46.3533 34.8661 46.3816 35.1391 46.3349 35.4037C46.2881 35.6683 46.1683 35.9132 45.9898 36.1087C45.8291 36.3614 45.61 36.5685 45.3524 36.7114C45.0948 36.8543 44.8069 36.9283 44.5147 36.9269C43.4237 36.7843 42.4241 36.2231 41.7137 35.3542C39.6965 35.8041 37.7263 36.4556 35.8307 37.2996C35.3829 38.3207 34.8512 39.3 34.2414 40.2268C33.5565 41.2177 32.9682 41.6813 32.3887 41.6813C32.1569 41.6794 31.932 41.5996 31.7477 41.4541C31.4915 41.2955 31.2834 41.0658 31.1468 40.7911C31.0103 40.5164 30.9507 40.2075 30.975 39.8995Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.8175 49.6265C32.7132 49.4837 32.5778 49.3685 32.4224 49.2901C32.2106 49.1901 31.9789 49.1433 31.7463 49.1538H30.9297V51.881H31.9307C32.0654 51.88 32.199 51.8554 32.3258 51.8083C32.46 51.7598 32.5828 51.6824 32.6858 51.581C32.8058 51.4557 32.896 51.3032 32.9492 51.1355C33.0201 50.9066 33.0527 50.6668 33.0458 50.4265C33.0442 50.295 33.0294 50.164 33.0019 50.0356C32.9677 49.8877 32.905 49.7486 32.8175 49.6265Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M38.2249 30.5361C37.7342 32.3081 37.1479 34.0502 36.4688 35.7542C37.8709 35.1904 39.3084 34.7257 40.7712 34.3633C39.8031 33.1773 38.9502 31.8954 38.2249 30.5361Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M23.0078 46.272V56.6172C23.0752 57.0135 23.2783 57.3713 23.5799 57.6251C23.8815 57.8789 24.2613 58.0117 24.6498 57.999H51.3428C51.7312 58.0117 52.1111 57.8789 52.4127 57.6251C52.7142 57.3713 52.9174 57.0135 52.9848 56.6172V46.272H23.0078ZM33.9309 51.3991C33.8228 51.6724 33.6579 51.9176 33.4479 52.1173C33.2366 52.3062 32.9922 52.4513 32.7279 52.5446C32.4174 52.6559 32.0907 52.7113 31.7621 52.7082H30.9542V55.2718H29.8303V48.2901H31.7621C32.0605 48.2883 32.3572 48.3374 32.6401 48.4356C32.9156 48.5273 33.1719 48.6723 33.3953 48.8628C33.6159 49.0508 33.798 49.2827 33.9309 49.5446C34.0705 49.8254 34.1399 50.138 34.1328 50.4537C34.1266 50.7796 34.0579 51.1009 33.9309 51.3991ZM40.6305 53.0355C40.5328 53.3967 40.3818 53.7402 40.1827 54.0536C40.0194 54.3074 39.8108 54.5265 39.568 54.6991C39.3631 54.8444 39.142 54.9634 38.9095 55.0536C38.7223 55.1269 38.528 55.1788 38.3299 55.2082H37.9348H35.3621V48.2901H37.3816C37.8924 48.2802 38.3999 48.3761 38.8743 48.5719C39.2742 48.738 39.6361 48.9888 39.9368 49.3083C40.2375 49.605 40.474 49.9644 40.6305 50.3628C40.7694 50.7497 40.8407 51.1591 40.8412 51.5719C40.8399 52.0677 40.7689 52.5608 40.6305 53.0355ZM46.5925 49.1446H43.5017V51.3355H46.3027V52.1082H43.5017V55.2718H42.3866V48.2901H46.5837L46.5925 49.1446Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M39.18 49.9177C38.965 49.6721 38.6974 49.4822 38.3985 49.3632C37.9912 49.2089 37.5588 49.1379 37.1254 49.1541H36.4844V54.4176H37.5644C37.8706 54.4464 38.1788 54.3939 38.46 54.2651C38.7411 54.1362 38.9859 53.9353 39.1712 53.6813C39.5369 53.0391 39.7082 52.2981 39.6629 51.5541C39.6635 51.2663 39.6311 50.9794 39.5664 50.6995C39.5012 50.4102 39.3686 50.1417 39.18 49.9177Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M37.8199 24.6998C37.5628 25.0117 37.4268 25.4113 37.4382 25.8211C37.4495 26.2309 37.6074 26.6218 37.8813 26.9179C38.5135 25.4543 37.8462 24.6907 37.8199 24.6998Z"
                          fill="#F5F5F5"
                        />
                      </svg>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5
                          className="card-titleQ"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            overflow: "hidden",
                            marginLeft: urlPage === "en" ? "-8%" : "",
                          }}
                        >
                          {t("Core Values and Professional Ethics")}
                        </h5>

                        <div
                          className="container"
                          style={{
                            textAlign: urlPage === "en" ? "right" : "left",
                          }}
                        >
                          <Link
                            to={pdf8}
                            style={{ textDecoration: "none" }}
                            target="_blank"
                          >
                            <button className="btn-info" style={{}}>
                              {t("More")}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cols">
                <div
                  className="card mb-3"
                  style={{
                    border: "none",
                    marginRight: "2%",
                    marginLeft: urlPage === "en" ? "2.5%" : "",
                  }}
                >
                  <div className="row g-0">
                    <div className="col-md-4">
                      <svg
                        width="76"
                        height="76"
                        viewBox="0 0 76 76"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "10%",
                        }}
                      >
                        <rect width="76" height="76" rx="38" fill="#142B7B" />
                        <path
                          d="M45.0226 35.4178C45.0857 35.3321 45.136 35.2371 45.1719 35.136C45.0929 35.136 44.9787 34.9996 44.3904 34.9996C43.9884 34.9976 43.5866 35.0219 43.1875 35.0724C43.7319 35.5087 43.8636 35.7269 44.2148 35.7269C44.3632 35.7409 44.5127 35.7204 44.6525 35.6669C44.7922 35.6134 44.9187 35.5283 45.0226 35.4178Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.3637 40.5084C32.4603 40.5084 33.0398 40.1993 34.1198 38.1357C32.6973 38.963 32.1178 39.6448 32.0739 40.0266C32.0651 40.0902 32.0476 40.263 32.3637 40.5084Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M52.9879 44.8994V27.6271C53.0136 27.4057 52.994 27.1813 52.9304 26.9683C52.8669 26.7553 52.7609 26.5586 52.6192 26.3907L44.8659 18.3818C44.633 18.1401 44.3176 18.003 43.9878 18H24.653C24.4149 18.0145 24.1822 18.0791 23.969 18.1897C23.7558 18.3004 23.5666 18.4548 23.413 18.6437C23.2594 18.8325 23.1445 19.0518 23.0754 19.2881C23.0063 19.5244 22.9844 19.7728 23.011 20.0181V44.8994H52.9879ZM43.663 20.3545C43.6614 20.2832 43.6807 20.2132 43.7182 20.1534C43.7557 20.0937 43.8098 20.047 43.8733 20.0195C43.9368 19.9921 44.0068 19.985 44.0742 19.9994C44.1417 20.0137 44.2034 20.0488 44.2513 20.1L50.9684 27.0907C51.0178 27.1403 51.0517 27.2042 51.0655 27.274C51.0794 27.3438 51.0726 27.4163 51.0461 27.4821C51.0195 27.5478 50.9745 27.6038 50.9168 27.6426C50.859 27.6814 50.7914 27.7014 50.7225 27.6998H43.6981L43.663 20.3545ZM30.975 39.8995C31.0979 38.7814 32.4414 37.5996 34.9614 36.4087C36.0028 34.0144 36.8458 31.5328 37.4814 28.9907C36.8141 27.4907 36.1731 25.5453 36.6034 24.4453C36.661 24.2564 36.7587 24.0832 36.8893 23.9385C37.02 23.7938 37.1803 23.6812 37.3585 23.609C37.5864 23.5309 37.822 23.4791 38.0609 23.4544C38.3946 23.4544 38.6931 23.8999 38.939 24.1817C39.1848 24.4635 39.58 24.9908 38.6931 28.8725C39.5761 30.7707 40.7174 32.528 42.0824 34.0906C42.8205 33.9415 43.5697 33.8594 44.3215 33.8451C45.3664 33.8451 45.9986 34.0997 46.2532 34.6178C46.3533 34.8661 46.3816 35.1391 46.3349 35.4037C46.2881 35.6683 46.1683 35.9132 45.9898 36.1087C45.8291 36.3614 45.61 36.5685 45.3524 36.7114C45.0948 36.8543 44.8069 36.9283 44.5147 36.9269C43.4237 36.7843 42.4241 36.2231 41.7137 35.3542C39.6965 35.8041 37.7263 36.4556 35.8307 37.2996C35.3829 38.3207 34.8512 39.3 34.2414 40.2268C33.5565 41.2177 32.9682 41.6813 32.3887 41.6813C32.1569 41.6794 31.932 41.5996 31.7477 41.4541C31.4915 41.2955 31.2834 41.0658 31.1468 40.7911C31.0103 40.5164 30.9507 40.2075 30.975 39.8995Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.8175 49.6265C32.7132 49.4837 32.5778 49.3685 32.4224 49.2901C32.2106 49.1901 31.9789 49.1433 31.7463 49.1538H30.9297V51.881H31.9307C32.0654 51.88 32.199 51.8554 32.3258 51.8083C32.46 51.7598 32.5828 51.6824 32.6858 51.581C32.8058 51.4557 32.896 51.3032 32.9492 51.1355C33.0201 50.9066 33.0527 50.6668 33.0458 50.4265C33.0442 50.295 33.0294 50.164 33.0019 50.0356C32.9677 49.8877 32.905 49.7486 32.8175 49.6265Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M38.2249 30.5361C37.7342 32.3081 37.1479 34.0502 36.4688 35.7542C37.8709 35.1904 39.3084 34.7257 40.7712 34.3633C39.8031 33.1773 38.9502 31.8954 38.2249 30.5361Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M23.0078 46.272V56.6172C23.0752 57.0135 23.2783 57.3713 23.5799 57.6251C23.8815 57.8789 24.2613 58.0117 24.6498 57.999H51.3428C51.7312 58.0117 52.1111 57.8789 52.4127 57.6251C52.7142 57.3713 52.9174 57.0135 52.9848 56.6172V46.272H23.0078ZM33.9309 51.3991C33.8228 51.6724 33.6579 51.9176 33.4479 52.1173C33.2366 52.3062 32.9922 52.4513 32.7279 52.5446C32.4174 52.6559 32.0907 52.7113 31.7621 52.7082H30.9542V55.2718H29.8303V48.2901H31.7621C32.0605 48.2883 32.3572 48.3374 32.6401 48.4356C32.9156 48.5273 33.1719 48.6723 33.3953 48.8628C33.6159 49.0508 33.798 49.2827 33.9309 49.5446C34.0705 49.8254 34.1399 50.138 34.1328 50.4537C34.1266 50.7796 34.0579 51.1009 33.9309 51.3991ZM40.6305 53.0355C40.5328 53.3967 40.3818 53.7402 40.1827 54.0536C40.0194 54.3074 39.8108 54.5265 39.568 54.6991C39.3631 54.8444 39.142 54.9634 38.9095 55.0536C38.7223 55.1269 38.528 55.1788 38.3299 55.2082H37.9348H35.3621V48.2901H37.3816C37.8924 48.2802 38.3999 48.3761 38.8743 48.5719C39.2742 48.738 39.6361 48.9888 39.9368 49.3083C40.2375 49.605 40.474 49.9644 40.6305 50.3628C40.7694 50.7497 40.8407 51.1591 40.8412 51.5719C40.8399 52.0677 40.7689 52.5608 40.6305 53.0355ZM46.5925 49.1446H43.5017V51.3355H46.3027V52.1082H43.5017V55.2718H42.3866V48.2901H46.5837L46.5925 49.1446Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M39.18 49.9177C38.965 49.6721 38.6974 49.4822 38.3985 49.3632C37.9912 49.2089 37.5588 49.1379 37.1254 49.1541H36.4844V54.4176H37.5644C37.8706 54.4464 38.1788 54.3939 38.46 54.2651C38.7411 54.1362 38.9859 53.9353 39.1712 53.6813C39.5369 53.0391 39.7082 52.2981 39.6629 51.5541C39.6635 51.2663 39.6311 50.9794 39.5664 50.6995C39.5012 50.4102 39.3686 50.1417 39.18 49.9177Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M37.8199 24.6998C37.5628 25.0117 37.4268 25.4113 37.4382 25.8211C37.4495 26.2309 37.6074 26.6218 37.8813 26.9179C38.5135 25.4543 37.8462 24.6907 37.8199 24.6998Z"
                          fill="#F5F5F5"
                        />
                      </svg>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5
                          className="card-titleQ"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            overflow: "hidden",
                            marginLeft: urlPage === "en" ? "-21%" : "",
                          }}
                        >
                          {t(
                            "Training Plan for the Faculty of Computing 2021-2026"
                          )}
                        </h5>

                        <div
                          className="container"
                          style={{
                            textAlign: urlPage === "en" ? "right" : "left",
                          }}
                        >
                          <Link
                            to={pdf9}
                            style={{ textDecoration: "none" }}
                            target="_blank"
                          >
                            <button className="btn-info" style={{}}>
                              {t("More")}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cols">
                <div
                  className="card mb-3"
                  style={{
                    border: "none",
                    marginRight: "2%",
                    marginLeft: urlPage === "en" ? "2.5%" : "",
                  }}
                >
                  <div className="row g-0">
                    <div className="col-md-4">
                      <svg
                        width="76"
                        height="76"
                        viewBox="0 0 76 76"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "10%",
                        }}
                      >
                        <rect width="76" height="76" rx="38" fill="#142B7B" />
                        <path
                          d="M45.0226 35.4178C45.0857 35.3321 45.136 35.2371 45.1719 35.136C45.0929 35.136 44.9787 34.9996 44.3904 34.9996C43.9884 34.9976 43.5866 35.0219 43.1875 35.0724C43.7319 35.5087 43.8636 35.7269 44.2148 35.7269C44.3632 35.7409 44.5127 35.7204 44.6525 35.6669C44.7922 35.6134 44.9187 35.5283 45.0226 35.4178Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.3637 40.5084C32.4603 40.5084 33.0398 40.1993 34.1198 38.1357C32.6973 38.963 32.1178 39.6448 32.0739 40.0266C32.0651 40.0902 32.0476 40.263 32.3637 40.5084Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M52.9879 44.8994V27.6271C53.0136 27.4057 52.994 27.1813 52.9304 26.9683C52.8669 26.7553 52.7609 26.5586 52.6192 26.3907L44.8659 18.3818C44.633 18.1401 44.3176 18.003 43.9878 18H24.653C24.4149 18.0145 24.1822 18.0791 23.969 18.1897C23.7558 18.3004 23.5666 18.4548 23.413 18.6437C23.2594 18.8325 23.1445 19.0518 23.0754 19.2881C23.0063 19.5244 22.9844 19.7728 23.011 20.0181V44.8994H52.9879ZM43.663 20.3545C43.6614 20.2832 43.6807 20.2132 43.7182 20.1534C43.7557 20.0937 43.8098 20.047 43.8733 20.0195C43.9368 19.9921 44.0068 19.985 44.0742 19.9994C44.1417 20.0137 44.2034 20.0488 44.2513 20.1L50.9684 27.0907C51.0178 27.1403 51.0517 27.2042 51.0655 27.274C51.0794 27.3438 51.0726 27.4163 51.0461 27.4821C51.0195 27.5478 50.9745 27.6038 50.9168 27.6426C50.859 27.6814 50.7914 27.7014 50.7225 27.6998H43.6981L43.663 20.3545ZM30.975 39.8995C31.0979 38.7814 32.4414 37.5996 34.9614 36.4087C36.0028 34.0144 36.8458 31.5328 37.4814 28.9907C36.8141 27.4907 36.1731 25.5453 36.6034 24.4453C36.661 24.2564 36.7587 24.0832 36.8893 23.9385C37.02 23.7938 37.1803 23.6812 37.3585 23.609C37.5864 23.5309 37.822 23.4791 38.0609 23.4544C38.3946 23.4544 38.6931 23.8999 38.939 24.1817C39.1848 24.4635 39.58 24.9908 38.6931 28.8725C39.5761 30.7707 40.7174 32.528 42.0824 34.0906C42.8205 33.9415 43.5697 33.8594 44.3215 33.8451C45.3664 33.8451 45.9986 34.0997 46.2532 34.6178C46.3533 34.8661 46.3816 35.1391 46.3349 35.4037C46.2881 35.6683 46.1683 35.9132 45.9898 36.1087C45.8291 36.3614 45.61 36.5685 45.3524 36.7114C45.0948 36.8543 44.8069 36.9283 44.5147 36.9269C43.4237 36.7843 42.4241 36.2231 41.7137 35.3542C39.6965 35.8041 37.7263 36.4556 35.8307 37.2996C35.3829 38.3207 34.8512 39.3 34.2414 40.2268C33.5565 41.2177 32.9682 41.6813 32.3887 41.6813C32.1569 41.6794 31.932 41.5996 31.7477 41.4541C31.4915 41.2955 31.2834 41.0658 31.1468 40.7911C31.0103 40.5164 30.9507 40.2075 30.975 39.8995Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.8175 49.6265C32.7132 49.4837 32.5778 49.3685 32.4224 49.2901C32.2106 49.1901 31.9789 49.1433 31.7463 49.1538H30.9297V51.881H31.9307C32.0654 51.88 32.199 51.8554 32.3258 51.8083C32.46 51.7598 32.5828 51.6824 32.6858 51.581C32.8058 51.4557 32.896 51.3032 32.9492 51.1355C33.0201 50.9066 33.0527 50.6668 33.0458 50.4265C33.0442 50.295 33.0294 50.164 33.0019 50.0356C32.9677 49.8877 32.905 49.7486 32.8175 49.6265Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M38.2249 30.5361C37.7342 32.3081 37.1479 34.0502 36.4688 35.7542C37.8709 35.1904 39.3084 34.7257 40.7712 34.3633C39.8031 33.1773 38.9502 31.8954 38.2249 30.5361Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M23.0078 46.272V56.6172C23.0752 57.0135 23.2783 57.3713 23.5799 57.6251C23.8815 57.8789 24.2613 58.0117 24.6498 57.999H51.3428C51.7312 58.0117 52.1111 57.8789 52.4127 57.6251C52.7142 57.3713 52.9174 57.0135 52.9848 56.6172V46.272H23.0078ZM33.9309 51.3991C33.8228 51.6724 33.6579 51.9176 33.4479 52.1173C33.2366 52.3062 32.9922 52.4513 32.7279 52.5446C32.4174 52.6559 32.0907 52.7113 31.7621 52.7082H30.9542V55.2718H29.8303V48.2901H31.7621C32.0605 48.2883 32.3572 48.3374 32.6401 48.4356C32.9156 48.5273 33.1719 48.6723 33.3953 48.8628C33.6159 49.0508 33.798 49.2827 33.9309 49.5446C34.0705 49.8254 34.1399 50.138 34.1328 50.4537C34.1266 50.7796 34.0579 51.1009 33.9309 51.3991ZM40.6305 53.0355C40.5328 53.3967 40.3818 53.7402 40.1827 54.0536C40.0194 54.3074 39.8108 54.5265 39.568 54.6991C39.3631 54.8444 39.142 54.9634 38.9095 55.0536C38.7223 55.1269 38.528 55.1788 38.3299 55.2082H37.9348H35.3621V48.2901H37.3816C37.8924 48.2802 38.3999 48.3761 38.8743 48.5719C39.2742 48.738 39.6361 48.9888 39.9368 49.3083C40.2375 49.605 40.474 49.9644 40.6305 50.3628C40.7694 50.7497 40.8407 51.1591 40.8412 51.5719C40.8399 52.0677 40.7689 52.5608 40.6305 53.0355ZM46.5925 49.1446H43.5017V51.3355H46.3027V52.1082H43.5017V55.2718H42.3866V48.2901H46.5837L46.5925 49.1446Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M39.18 49.9177C38.965 49.6721 38.6974 49.4822 38.3985 49.3632C37.9912 49.2089 37.5588 49.1379 37.1254 49.1541H36.4844V54.4176H37.5644C37.8706 54.4464 38.1788 54.3939 38.46 54.2651C38.7411 54.1362 38.9859 53.9353 39.1712 53.6813C39.5369 53.0391 39.7082 52.2981 39.6629 51.5541C39.6635 51.2663 39.6311 50.9794 39.5664 50.6995C39.5012 50.4102 39.3686 50.1417 39.18 49.9177Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M37.8199 24.6998C37.5628 25.0117 37.4268 25.4113 37.4382 25.8211C37.4495 26.2309 37.6074 26.6218 37.8813 26.9179C38.5135 25.4543 37.8462 24.6907 37.8199 24.6998Z"
                          fill="#F5F5F5"
                        />
                      </svg>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5
                          className="card-titleQ"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            overflow: "hidden",
                          }}
                        >
                          {t("Community Service Plan 2021-2026")}
                        </h5>

                        <div
                          className="container"
                          style={{
                            textAlign: urlPage === "en" ? "right" : "left",
                          }}
                        >
                          <Link
                            to={pdf10}
                            style={{ textDecoration: "none" }}
                            target="_blank"
                          >
                            <button className="btn-info" style={{}}>
                              {t("More")}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cols">
                <div
                  className="card mb-3"
                  style={{
                    border: "none",
                    marginRight: "2%",
                    marginLeft: urlPage === "en" ? "2.5%" : "",
                  }}
                >
                  <div className="row g-0">
                    <div className="col-md-4">
                      <svg
                        width="76"
                        height="76"
                        viewBox="0 0 76 76"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "10%",
                        }}
                      >
                        <rect width="76" height="76" rx="38" fill="#142B7B" />
                        <path
                          d="M45.0226 35.4178C45.0857 35.3321 45.136 35.2371 45.1719 35.136C45.0929 35.136 44.9787 34.9996 44.3904 34.9996C43.9884 34.9976 43.5866 35.0219 43.1875 35.0724C43.7319 35.5087 43.8636 35.7269 44.2148 35.7269C44.3632 35.7409 44.5127 35.7204 44.6525 35.6669C44.7922 35.6134 44.9187 35.5283 45.0226 35.4178Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.3637 40.5084C32.4603 40.5084 33.0398 40.1993 34.1198 38.1357C32.6973 38.963 32.1178 39.6448 32.0739 40.0266C32.0651 40.0902 32.0476 40.263 32.3637 40.5084Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M52.9879 44.8994V27.6271C53.0136 27.4057 52.994 27.1813 52.9304 26.9683C52.8669 26.7553 52.7609 26.5586 52.6192 26.3907L44.8659 18.3818C44.633 18.1401 44.3176 18.003 43.9878 18H24.653C24.4149 18.0145 24.1822 18.0791 23.969 18.1897C23.7558 18.3004 23.5666 18.4548 23.413 18.6437C23.2594 18.8325 23.1445 19.0518 23.0754 19.2881C23.0063 19.5244 22.9844 19.7728 23.011 20.0181V44.8994H52.9879ZM43.663 20.3545C43.6614 20.2832 43.6807 20.2132 43.7182 20.1534C43.7557 20.0937 43.8098 20.047 43.8733 20.0195C43.9368 19.9921 44.0068 19.985 44.0742 19.9994C44.1417 20.0137 44.2034 20.0488 44.2513 20.1L50.9684 27.0907C51.0178 27.1403 51.0517 27.2042 51.0655 27.274C51.0794 27.3438 51.0726 27.4163 51.0461 27.4821C51.0195 27.5478 50.9745 27.6038 50.9168 27.6426C50.859 27.6814 50.7914 27.7014 50.7225 27.6998H43.6981L43.663 20.3545ZM30.975 39.8995C31.0979 38.7814 32.4414 37.5996 34.9614 36.4087C36.0028 34.0144 36.8458 31.5328 37.4814 28.9907C36.8141 27.4907 36.1731 25.5453 36.6034 24.4453C36.661 24.2564 36.7587 24.0832 36.8893 23.9385C37.02 23.7938 37.1803 23.6812 37.3585 23.609C37.5864 23.5309 37.822 23.4791 38.0609 23.4544C38.3946 23.4544 38.6931 23.8999 38.939 24.1817C39.1848 24.4635 39.58 24.9908 38.6931 28.8725C39.5761 30.7707 40.7174 32.528 42.0824 34.0906C42.8205 33.9415 43.5697 33.8594 44.3215 33.8451C45.3664 33.8451 45.9986 34.0997 46.2532 34.6178C46.3533 34.8661 46.3816 35.1391 46.3349 35.4037C46.2881 35.6683 46.1683 35.9132 45.9898 36.1087C45.8291 36.3614 45.61 36.5685 45.3524 36.7114C45.0948 36.8543 44.8069 36.9283 44.5147 36.9269C43.4237 36.7843 42.4241 36.2231 41.7137 35.3542C39.6965 35.8041 37.7263 36.4556 35.8307 37.2996C35.3829 38.3207 34.8512 39.3 34.2414 40.2268C33.5565 41.2177 32.9682 41.6813 32.3887 41.6813C32.1569 41.6794 31.932 41.5996 31.7477 41.4541C31.4915 41.2955 31.2834 41.0658 31.1468 40.7911C31.0103 40.5164 30.9507 40.2075 30.975 39.8995Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.8175 49.6265C32.7132 49.4837 32.5778 49.3685 32.4224 49.2901C32.2106 49.1901 31.9789 49.1433 31.7463 49.1538H30.9297V51.881H31.9307C32.0654 51.88 32.199 51.8554 32.3258 51.8083C32.46 51.7598 32.5828 51.6824 32.6858 51.581C32.8058 51.4557 32.896 51.3032 32.9492 51.1355C33.0201 50.9066 33.0527 50.6668 33.0458 50.4265C33.0442 50.295 33.0294 50.164 33.0019 50.0356C32.9677 49.8877 32.905 49.7486 32.8175 49.6265Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M38.2249 30.5361C37.7342 32.3081 37.1479 34.0502 36.4688 35.7542C37.8709 35.1904 39.3084 34.7257 40.7712 34.3633C39.8031 33.1773 38.9502 31.8954 38.2249 30.5361Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M23.0078 46.272V56.6172C23.0752 57.0135 23.2783 57.3713 23.5799 57.6251C23.8815 57.8789 24.2613 58.0117 24.6498 57.999H51.3428C51.7312 58.0117 52.1111 57.8789 52.4127 57.6251C52.7142 57.3713 52.9174 57.0135 52.9848 56.6172V46.272H23.0078ZM33.9309 51.3991C33.8228 51.6724 33.6579 51.9176 33.4479 52.1173C33.2366 52.3062 32.9922 52.4513 32.7279 52.5446C32.4174 52.6559 32.0907 52.7113 31.7621 52.7082H30.9542V55.2718H29.8303V48.2901H31.7621C32.0605 48.2883 32.3572 48.3374 32.6401 48.4356C32.9156 48.5273 33.1719 48.6723 33.3953 48.8628C33.6159 49.0508 33.798 49.2827 33.9309 49.5446C34.0705 49.8254 34.1399 50.138 34.1328 50.4537C34.1266 50.7796 34.0579 51.1009 33.9309 51.3991ZM40.6305 53.0355C40.5328 53.3967 40.3818 53.7402 40.1827 54.0536C40.0194 54.3074 39.8108 54.5265 39.568 54.6991C39.3631 54.8444 39.142 54.9634 38.9095 55.0536C38.7223 55.1269 38.528 55.1788 38.3299 55.2082H37.9348H35.3621V48.2901H37.3816C37.8924 48.2802 38.3999 48.3761 38.8743 48.5719C39.2742 48.738 39.6361 48.9888 39.9368 49.3083C40.2375 49.605 40.474 49.9644 40.6305 50.3628C40.7694 50.7497 40.8407 51.1591 40.8412 51.5719C40.8399 52.0677 40.7689 52.5608 40.6305 53.0355ZM46.5925 49.1446H43.5017V51.3355H46.3027V52.1082H43.5017V55.2718H42.3866V48.2901H46.5837L46.5925 49.1446Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M39.18 49.9177C38.965 49.6721 38.6974 49.4822 38.3985 49.3632C37.9912 49.2089 37.5588 49.1379 37.1254 49.1541H36.4844V54.4176H37.5644C37.8706 54.4464 38.1788 54.3939 38.46 54.2651C38.7411 54.1362 38.9859 53.9353 39.1712 53.6813C39.5369 53.0391 39.7082 52.2981 39.6629 51.5541C39.6635 51.2663 39.6311 50.9794 39.5664 50.6995C39.5012 50.4102 39.3686 50.1417 39.18 49.9177Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M37.8199 24.6998C37.5628 25.0117 37.4268 25.4113 37.4382 25.8211C37.4495 26.2309 37.6074 26.6218 37.8813 26.9179C38.5135 25.4543 37.8462 24.6907 37.8199 24.6998Z"
                          fill="#F5F5F5"
                        />
                      </svg>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5
                          className="card-titleQ"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            overflow: "hidden",
                            marginLeft: urlPage === "en" ? "-12%" : "",
                          }}
                        >
                          {t("Support for Students with Special Needs")}
                        </h5>

                        <div
                          className="container"
                          style={{
                            textAlign: urlPage === "en" ? "right" : "left",
                          }}
                        >
                          <Link
                            to={pdf11}
                            style={{ textDecoration: "none" }}
                            target="_blank"
                          >
                            <button className="btn-info" style={{}}>
                              {t("More")}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cols">
                <div
                  className="card mb-3"
                  style={{
                    border: "none",
                    marginRight: "2%",
                    marginLeft: urlPage === "en" ? "2.5%" : "",
                  }}
                >
                  <div className="row g-0">
                    <div className="col-md-4">
                      <svg
                        width="76"
                        height="76"
                        viewBox="0 0 76 76"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "10%",
                        }}
                      >
                        <rect width="76" height="76" rx="38" fill="#142B7B" />
                        <path
                          d="M45.0226 35.4178C45.0857 35.3321 45.136 35.2371 45.1719 35.136C45.0929 35.136 44.9787 34.9996 44.3904 34.9996C43.9884 34.9976 43.5866 35.0219 43.1875 35.0724C43.7319 35.5087 43.8636 35.7269 44.2148 35.7269C44.3632 35.7409 44.5127 35.7204 44.6525 35.6669C44.7922 35.6134 44.9187 35.5283 45.0226 35.4178Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.3637 40.5084C32.4603 40.5084 33.0398 40.1993 34.1198 38.1357C32.6973 38.963 32.1178 39.6448 32.0739 40.0266C32.0651 40.0902 32.0476 40.263 32.3637 40.5084Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M52.9879 44.8994V27.6271C53.0136 27.4057 52.994 27.1813 52.9304 26.9683C52.8669 26.7553 52.7609 26.5586 52.6192 26.3907L44.8659 18.3818C44.633 18.1401 44.3176 18.003 43.9878 18H24.653C24.4149 18.0145 24.1822 18.0791 23.969 18.1897C23.7558 18.3004 23.5666 18.4548 23.413 18.6437C23.2594 18.8325 23.1445 19.0518 23.0754 19.2881C23.0063 19.5244 22.9844 19.7728 23.011 20.0181V44.8994H52.9879ZM43.663 20.3545C43.6614 20.2832 43.6807 20.2132 43.7182 20.1534C43.7557 20.0937 43.8098 20.047 43.8733 20.0195C43.9368 19.9921 44.0068 19.985 44.0742 19.9994C44.1417 20.0137 44.2034 20.0488 44.2513 20.1L50.9684 27.0907C51.0178 27.1403 51.0517 27.2042 51.0655 27.274C51.0794 27.3438 51.0726 27.4163 51.0461 27.4821C51.0195 27.5478 50.9745 27.6038 50.9168 27.6426C50.859 27.6814 50.7914 27.7014 50.7225 27.6998H43.6981L43.663 20.3545ZM30.975 39.8995C31.0979 38.7814 32.4414 37.5996 34.9614 36.4087C36.0028 34.0144 36.8458 31.5328 37.4814 28.9907C36.8141 27.4907 36.1731 25.5453 36.6034 24.4453C36.661 24.2564 36.7587 24.0832 36.8893 23.9385C37.02 23.7938 37.1803 23.6812 37.3585 23.609C37.5864 23.5309 37.822 23.4791 38.0609 23.4544C38.3946 23.4544 38.6931 23.8999 38.939 24.1817C39.1848 24.4635 39.58 24.9908 38.6931 28.8725C39.5761 30.7707 40.7174 32.528 42.0824 34.0906C42.8205 33.9415 43.5697 33.8594 44.3215 33.8451C45.3664 33.8451 45.9986 34.0997 46.2532 34.6178C46.3533 34.8661 46.3816 35.1391 46.3349 35.4037C46.2881 35.6683 46.1683 35.9132 45.9898 36.1087C45.8291 36.3614 45.61 36.5685 45.3524 36.7114C45.0948 36.8543 44.8069 36.9283 44.5147 36.9269C43.4237 36.7843 42.4241 36.2231 41.7137 35.3542C39.6965 35.8041 37.7263 36.4556 35.8307 37.2996C35.3829 38.3207 34.8512 39.3 34.2414 40.2268C33.5565 41.2177 32.9682 41.6813 32.3887 41.6813C32.1569 41.6794 31.932 41.5996 31.7477 41.4541C31.4915 41.2955 31.2834 41.0658 31.1468 40.7911C31.0103 40.5164 30.9507 40.2075 30.975 39.8995Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.8175 49.6265C32.7132 49.4837 32.5778 49.3685 32.4224 49.2901C32.2106 49.1901 31.9789 49.1433 31.7463 49.1538H30.9297V51.881H31.9307C32.0654 51.88 32.199 51.8554 32.3258 51.8083C32.46 51.7598 32.5828 51.6824 32.6858 51.581C32.8058 51.4557 32.896 51.3032 32.9492 51.1355C33.0201 50.9066 33.0527 50.6668 33.0458 50.4265C33.0442 50.295 33.0294 50.164 33.0019 50.0356C32.9677 49.8877 32.905 49.7486 32.8175 49.6265Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M38.2249 30.5361C37.7342 32.3081 37.1479 34.0502 36.4688 35.7542C37.8709 35.1904 39.3084 34.7257 40.7712 34.3633C39.8031 33.1773 38.9502 31.8954 38.2249 30.5361Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M23.0078 46.272V56.6172C23.0752 57.0135 23.2783 57.3713 23.5799 57.6251C23.8815 57.8789 24.2613 58.0117 24.6498 57.999H51.3428C51.7312 58.0117 52.1111 57.8789 52.4127 57.6251C52.7142 57.3713 52.9174 57.0135 52.9848 56.6172V46.272H23.0078ZM33.9309 51.3991C33.8228 51.6724 33.6579 51.9176 33.4479 52.1173C33.2366 52.3062 32.9922 52.4513 32.7279 52.5446C32.4174 52.6559 32.0907 52.7113 31.7621 52.7082H30.9542V55.2718H29.8303V48.2901H31.7621C32.0605 48.2883 32.3572 48.3374 32.6401 48.4356C32.9156 48.5273 33.1719 48.6723 33.3953 48.8628C33.6159 49.0508 33.798 49.2827 33.9309 49.5446C34.0705 49.8254 34.1399 50.138 34.1328 50.4537C34.1266 50.7796 34.0579 51.1009 33.9309 51.3991ZM40.6305 53.0355C40.5328 53.3967 40.3818 53.7402 40.1827 54.0536C40.0194 54.3074 39.8108 54.5265 39.568 54.6991C39.3631 54.8444 39.142 54.9634 38.9095 55.0536C38.7223 55.1269 38.528 55.1788 38.3299 55.2082H37.9348H35.3621V48.2901H37.3816C37.8924 48.2802 38.3999 48.3761 38.8743 48.5719C39.2742 48.738 39.6361 48.9888 39.9368 49.3083C40.2375 49.605 40.474 49.9644 40.6305 50.3628C40.7694 50.7497 40.8407 51.1591 40.8412 51.5719C40.8399 52.0677 40.7689 52.5608 40.6305 53.0355ZM46.5925 49.1446H43.5017V51.3355H46.3027V52.1082H43.5017V55.2718H42.3866V48.2901H46.5837L46.5925 49.1446Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M39.18 49.9177C38.965 49.6721 38.6974 49.4822 38.3985 49.3632C37.9912 49.2089 37.5588 49.1379 37.1254 49.1541H36.4844V54.4176H37.5644C37.8706 54.4464 38.1788 54.3939 38.46 54.2651C38.7411 54.1362 38.9859 53.9353 39.1712 53.6813C39.5369 53.0391 39.7082 52.2981 39.6629 51.5541C39.6635 51.2663 39.6311 50.9794 39.5664 50.6995C39.5012 50.4102 39.3686 50.1417 39.18 49.9177Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M37.8199 24.6998C37.5628 25.0117 37.4268 25.4113 37.4382 25.8211C37.4495 26.2309 37.6074 26.6218 37.8813 26.9179C38.5135 25.4543 37.8462 24.6907 37.8199 24.6998Z"
                          fill="#F5F5F5"
                        />
                      </svg>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5
                          className="card-titleQ"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            overflow: "hidden",
                          }}
                        >
                          {t("Field Training Guide")}
                        </h5>

                        <div
                          className="container"
                          style={{
                            textAlign: urlPage === "en" ? "right" : "left",
                          }}
                        >
                          <Link
                            to={pdf12}
                            style={{ textDecoration: "none" }}
                            target="_blank"
                          >
                            <button className="btn-info" style={{}}>
                              {t("More")}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cols">
                <div
                  className="card mb-3"
                  style={{
                    border: "none",
                    marginRight: "2%",
                    marginLeft: urlPage === "en" ? "2.5%" : "",
                  }}
                >
                  <div className="row g-0">
                    <div className="col-md-4">
                      <svg
                        width="76"
                        height="76"
                        viewBox="0 0 76 76"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "10%",
                        }}
                      >
                        <rect width="76" height="76" rx="38" fill="#142B7B" />
                        <path
                          d="M45.0226 35.4178C45.0857 35.3321 45.136 35.2371 45.1719 35.136C45.0929 35.136 44.9787 34.9996 44.3904 34.9996C43.9884 34.9976 43.5866 35.0219 43.1875 35.0724C43.7319 35.5087 43.8636 35.7269 44.2148 35.7269C44.3632 35.7409 44.5127 35.7204 44.6525 35.6669C44.7922 35.6134 44.9187 35.5283 45.0226 35.4178Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.3637 40.5084C32.4603 40.5084 33.0398 40.1993 34.1198 38.1357C32.6973 38.963 32.1178 39.6448 32.0739 40.0266C32.0651 40.0902 32.0476 40.263 32.3637 40.5084Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M52.9879 44.8994V27.6271C53.0136 27.4057 52.994 27.1813 52.9304 26.9683C52.8669 26.7553 52.7609 26.5586 52.6192 26.3907L44.8659 18.3818C44.633 18.1401 44.3176 18.003 43.9878 18H24.653C24.4149 18.0145 24.1822 18.0791 23.969 18.1897C23.7558 18.3004 23.5666 18.4548 23.413 18.6437C23.2594 18.8325 23.1445 19.0518 23.0754 19.2881C23.0063 19.5244 22.9844 19.7728 23.011 20.0181V44.8994H52.9879ZM43.663 20.3545C43.6614 20.2832 43.6807 20.2132 43.7182 20.1534C43.7557 20.0937 43.8098 20.047 43.8733 20.0195C43.9368 19.9921 44.0068 19.985 44.0742 19.9994C44.1417 20.0137 44.2034 20.0488 44.2513 20.1L50.9684 27.0907C51.0178 27.1403 51.0517 27.2042 51.0655 27.274C51.0794 27.3438 51.0726 27.4163 51.0461 27.4821C51.0195 27.5478 50.9745 27.6038 50.9168 27.6426C50.859 27.6814 50.7914 27.7014 50.7225 27.6998H43.6981L43.663 20.3545ZM30.975 39.8995C31.0979 38.7814 32.4414 37.5996 34.9614 36.4087C36.0028 34.0144 36.8458 31.5328 37.4814 28.9907C36.8141 27.4907 36.1731 25.5453 36.6034 24.4453C36.661 24.2564 36.7587 24.0832 36.8893 23.9385C37.02 23.7938 37.1803 23.6812 37.3585 23.609C37.5864 23.5309 37.822 23.4791 38.0609 23.4544C38.3946 23.4544 38.6931 23.8999 38.939 24.1817C39.1848 24.4635 39.58 24.9908 38.6931 28.8725C39.5761 30.7707 40.7174 32.528 42.0824 34.0906C42.8205 33.9415 43.5697 33.8594 44.3215 33.8451C45.3664 33.8451 45.9986 34.0997 46.2532 34.6178C46.3533 34.8661 46.3816 35.1391 46.3349 35.4037C46.2881 35.6683 46.1683 35.9132 45.9898 36.1087C45.8291 36.3614 45.61 36.5685 45.3524 36.7114C45.0948 36.8543 44.8069 36.9283 44.5147 36.9269C43.4237 36.7843 42.4241 36.2231 41.7137 35.3542C39.6965 35.8041 37.7263 36.4556 35.8307 37.2996C35.3829 38.3207 34.8512 39.3 34.2414 40.2268C33.5565 41.2177 32.9682 41.6813 32.3887 41.6813C32.1569 41.6794 31.932 41.5996 31.7477 41.4541C31.4915 41.2955 31.2834 41.0658 31.1468 40.7911C31.0103 40.5164 30.9507 40.2075 30.975 39.8995Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M32.8175 49.6265C32.7132 49.4837 32.5778 49.3685 32.4224 49.2901C32.2106 49.1901 31.9789 49.1433 31.7463 49.1538H30.9297V51.881H31.9307C32.0654 51.88 32.199 51.8554 32.3258 51.8083C32.46 51.7598 32.5828 51.6824 32.6858 51.581C32.8058 51.4557 32.896 51.3032 32.9492 51.1355C33.0201 50.9066 33.0527 50.6668 33.0458 50.4265C33.0442 50.295 33.0294 50.164 33.0019 50.0356C32.9677 49.8877 32.905 49.7486 32.8175 49.6265Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M38.2249 30.5361C37.7342 32.3081 37.1479 34.0502 36.4688 35.7542C37.8709 35.1904 39.3084 34.7257 40.7712 34.3633C39.8031 33.1773 38.9502 31.8954 38.2249 30.5361Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M23.0078 46.272V56.6172C23.0752 57.0135 23.2783 57.3713 23.5799 57.6251C23.8815 57.8789 24.2613 58.0117 24.6498 57.999H51.3428C51.7312 58.0117 52.1111 57.8789 52.4127 57.6251C52.7142 57.3713 52.9174 57.0135 52.9848 56.6172V46.272H23.0078ZM33.9309 51.3991C33.8228 51.6724 33.6579 51.9176 33.4479 52.1173C33.2366 52.3062 32.9922 52.4513 32.7279 52.5446C32.4174 52.6559 32.0907 52.7113 31.7621 52.7082H30.9542V55.2718H29.8303V48.2901H31.7621C32.0605 48.2883 32.3572 48.3374 32.6401 48.4356C32.9156 48.5273 33.1719 48.6723 33.3953 48.8628C33.6159 49.0508 33.798 49.2827 33.9309 49.5446C34.0705 49.8254 34.1399 50.138 34.1328 50.4537C34.1266 50.7796 34.0579 51.1009 33.9309 51.3991ZM40.6305 53.0355C40.5328 53.3967 40.3818 53.7402 40.1827 54.0536C40.0194 54.3074 39.8108 54.5265 39.568 54.6991C39.3631 54.8444 39.142 54.9634 38.9095 55.0536C38.7223 55.1269 38.528 55.1788 38.3299 55.2082H37.9348H35.3621V48.2901H37.3816C37.8924 48.2802 38.3999 48.3761 38.8743 48.5719C39.2742 48.738 39.6361 48.9888 39.9368 49.3083C40.2375 49.605 40.474 49.9644 40.6305 50.3628C40.7694 50.7497 40.8407 51.1591 40.8412 51.5719C40.8399 52.0677 40.7689 52.5608 40.6305 53.0355ZM46.5925 49.1446H43.5017V51.3355H46.3027V52.1082H43.5017V55.2718H42.3866V48.2901H46.5837L46.5925 49.1446Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M39.18 49.9177C38.965 49.6721 38.6974 49.4822 38.3985 49.3632C37.9912 49.2089 37.5588 49.1379 37.1254 49.1541H36.4844V54.4176H37.5644C37.8706 54.4464 38.1788 54.3939 38.46 54.2651C38.7411 54.1362 38.9859 53.9353 39.1712 53.6813C39.5369 53.0391 39.7082 52.2981 39.6629 51.5541C39.6635 51.2663 39.6311 50.9794 39.5664 50.6995C39.5012 50.4102 39.3686 50.1417 39.18 49.9177Z"
                          fill="#F5F5F5"
                        />
                        <path
                          d="M37.8199 24.6998C37.5628 25.0117 37.4268 25.4113 37.4382 25.8211C37.4495 26.2309 37.6074 26.6218 37.8813 26.9179C38.5135 25.4543 37.8462 24.6907 37.8199 24.6998Z"
                          fill="#F5F5F5"
                        />
                      </svg>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5
                          className="card-titleQ"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            overflow: "hidden",
                          }}
                        >
                          {t("Examinations Management Guide")}
                        </h5>

                        <div
                          className="container"
                          style={{
                            textAlign: urlPage === "en" ? "right" : "left",
                          }}
                        >
                          <Link
                            to={pdf13}
                            style={{ textDecoration: "none" }}
                            target="_blank"
                          >
                            <button className="btn-info" style={{}}>
                              {t("More")}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}
export default FCIQuality;
